export const formFieldTypes = {
  SINGLELINETEXT: 'SingleLineText',
  EMAIL: 'Email',
  PHONE: 'Phone',
  MULTILINETEXT: 'MultiLineText',
  DROPDOWN: 'Dropdown',
  CHECKBOX: 'Checkbox',
  CHECKBOXLIST: 'CheckboxList',
  CAPTCHA: 'Captcha',
  SUBMIT: 'Submit',
  RADIOBUTTONLIST: 'RadioButtonList',
  DATE: 'Date',
  RANGEDATE: 'DateRange',
  FILE: 'File',
  NUMBER: 'Number',
  COUNTER: 'Counter',
  CUSTOMBUTTON: 'CustomButton'
};
