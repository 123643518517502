import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { mobileMedia, pxToRem } from '../../../../styles/themes/vad/constants';

export default makeStyles(theme => ({
  favRoot: {
    ...theme.mixins.marginRight(pxToRem(16)),
    width: '52px',
    '@media (min-width: 1440px) and (max-width: 1599px)': {
      width: '44px',
    },
    '@media (min-width: 1024px) and (max-width: 1439px)': {
      width: '35.97px',
    },
  },

  favoriteListWrapper: {
    marginTop: theme.spacing(2),
    borderRadius: borderRadius.b3,
    backgroundColor: theme.mixins.White(),
    boxShadow: `0px 4px 30px ${theme.mixins.Black(180)}`, // '0px 4px 30px rgba(0, 0, 0, 0.18)'
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    // maxWidth: '420px',
    maxWidth: '443px',
    overflowX: 'hidden',
    // margin: 'auto',
    minHeight: '200px',
    maxHeight:
      'calc((var(--vh, 1vh) * 100) - var(--notificationBar) - var(--headerHeight) - var(--transparentHeaderHeight))',
    boxSizing: 'content-box',
    scrollBehavior: 'smooth',
    overflowY: 'auto',

    '&.header-fav-tags': {
      '& .categoryAlign': {
        display: 'none',
      },
    },

    '& .fav-img-dimensions': {
      width: '56px',
      height: '56px',
      '& picture': {
        width: '56px',
        height: '56px',
      },
    },

    '.MuiAppBar-positionFixed &': {
      maxHeight:
        'calc((var(--vh, 1vh) * 100) - var(--headerHeight) - var(--transparentHeaderHeight))',
    },
    '& .fav-cta-align': {
      '[dir="rtl"] &': {
        textAlign: 'right',
      },
    },
    color: theme.mixins.Black(1000),
    [mobileMedia]: {
      minHeight: '166px',
      marginTop: 0,
      boxShadow: 'none',
      flexDirection: 'column',
      maxHeight: 'none',
      maxWidth: '100%',
      // paddingBottom: 'calc(var(--favBoxBotBar) - 3.43em)',
      paddingBottom: '24px',
    },
    '& .MuiList-padding': {
      padding: 0,
      width: pxToRem(300),
    },
    '& li': {
      padding: pxToRem(16),
      '&.selected': {
        backgroundColor: theme.mixins.Black(30),
        position: 'relative',
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(30),
      },
    },
    '& .heartIcon': {
      position: 'relative',
    },
    '& .fav-itinerary-title': {
      paddingBottom: '16px',
      display: 'block',
    },
  },

  title: {
    textAlign: theme.mixins.textLeft(),
    ...theme.mixins.marginLeft(pxToRem(23)),
    '&.MuiButtonBase-root': {
      '& .MuiButton-label': {
        justifyContent: 'flex-start',
      },
      '& .btn-txt': {
        fontSize: pxToRem(32),
        lineHeight: pxToRem(40),
      },
    },
    '[dir="rtl"] &': {
      display: 'flex',
    },
  },
  emptyListWrap: {
    paddingTop: `${pxToRem(24)}`,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    // textAlign: 'center',
    ...theme.mixins.paddingLeft(pxToRem(24)),
    ...theme.mixins.paddingRight(pxToRem(24)),
    // width: '420px',
    width: '443px',
    // height: '184px',
    '& .MuiTypography-h2': {
      [mobileMedia]: {
        fontSize: pxToRem(22),
        lineHeight: pxToRem(32),
      },
    },
    [mobileMedia]: {
      paddingTop: 0,
      width: '100%',
      height: '100%',
      textAlign: theme.mixins.textLeft(),
      // '& h2': {
      //   ...theme.mixins.paddingLeft(pxToRem(40)),
      // },
    },
  },
  emptyListSpan: {
    // color: theme.palette.common.UnselectedColor,
    color: '#898C8D',
    // paddingTop: pxToRem(12),
    // paddingLeft: pxToRem(48),
    // paddingRight: pxToRem(48),
    [mobileMedia]: {
      width: '65%',
      minWidth: '251px',
      // ...theme.mixins.paddingLeft(pxToRem(32)),
      // ...theme.mixins.paddingRight(pxToRem(0)),
    },
  },
  favList: {
    position: 'relative',
    width: '420px',
    height: '100%',
    // paddingTop: pxToRem(8),
    listStyleType: 'none',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    [mobileMedia]: {
      height: 'auto',
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& li': {
      // padding: pxToRem(16),
      margin: '0 24px',
      padding: `${pxToRem(16)} 0`,
      textAlign: theme.mixins.textLeft(),
    },
    // '& li:last-child': {
    //   borderBottom: '0px',
    // },
  },

  listItem: {
    position: 'relative',
  },
  listFooter: {
    position: 'relative',
    width: '420px',
    height: '72px',
    zIndex: 10,
    // borderTop: `1px solid ${theme.palette.common.DisabledGrey}`,
    backgroundColor: theme.mixins.White(),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 0 24px 0',
    [theme.breakpoints.down('md')]: {
      padding: '0 24px',
    },
    '&.fav-box-bot-bar': {
      [theme.breakpoints.down('md')]: {
        paddingTop: '16px',
      },
      height: 'unset',
      '& a': {
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
      '& .favCta': {
        ...theme.mixins.marginLeft(pxToRem(24)),

        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
          maxWidth: '100%',
          width: '100%',
          padding: '12px 15px',
          ...theme.mixins.marginLeft(pxToRem(0)),
        },
        '& .MuiButton-label': {
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(16),
            lineHeight: pxToRem(24),
          },
        },
        '& .MuiButton-endIcon': {
          marginLeft: '8px !important',
          marginBottom: '3px',
        },
      },
    },
    '& .favCta': {
      ...theme.mixins.marginLeft(pxToRem(16)),
      ...theme.mixins.paddingLR(20, 20),
    },
    '& .link': {
      display: 'block',
      color: '#FF8707',
      cursor: 'pointer',
      ...theme.mixins.marginLeft(pxToRem(16)),
      minHeight: pxToRem(40),
      display: 'flex',
      alignItems: 'center',
      '[dir="rtl"] &': {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      '& span': {
        fontSize: pxToRem(16),
        fontWeight: '700',
      },
      '& svg': {
        width: pxToRem(15),
        height: pxToRem(12),
        // marginRight: '12px'
        ...theme.mixins.marginRight(pxToRem(12)),
        '[dir="rtl"] &': {
          transform: 'rotate(180Deg)',
          ...theme.mixins.marginRight(pxToRem(0)),
          ...theme.mixins.marginLeft(pxToRem(12)),
        },
      },
      '&.isDisabled': {
        color: 'rgba(170, 170, 170, 1)',
      },
    },
    [mobileMedia]: {
      width: '100%',
      borderTop: 'none',
      // position: 'fixed',
      // bottom: '0px',
    },
  },

  itineraryList: {
    padding: '24px 24px 0 24px',
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.down('md')]: {
      height: '60%',
    },
    '&.itineraryListAlign': {
      backgroundColor: '#F7F7F7',
      [theme.breakpoints.down('md')]: {
        height: '100%',
      },
      // marginTop: '16px',
    },
    '& .mainHead': {
      color: '#000000',
      // maxWidth: '280px',
      width: '100%',
      fontSize: '22px',
      fontWeight: '700',
      lineHeight: '32px',
      fontFamily: theme.custom.fontFamily.primaryBold,
      '[dir="rtl"] &': {
        textAlign: 'right',
      },
    },
    // backgroundColor: '#FCEFDE',
    '& .itenaryListingWrapper': {
      cursor: 'pointer',
      '&.emptyList': {
        alignItems: 'center',
      },
      marginBottom: pxToRem(8),
      display: 'flex',
      '& .boxImg': {
        width: '56px',
        height: '56px',
        '&.itineraryImg': {
          '& picture': {
            width: '56px',
            height: '56px',
            textAlign: 'center',
            '& img': {
              maxWidth: '60px',
              width: '100%',
              objectFit: 'contain',
            },
          },
        },
        '&.normalImg': {
          '& picture': {
            width: '56px',
            height: '56px',
            textAlign: 'center',
            '& img': {
              maxWidth: '100%',
              width: '100%',
            },
          },
        },
        // '& picture': {
        //   width: '95px',
        //   height: '95px',
        //   textAlign: 'center',
        //   '& img': {
        //     maxWidth: '60px',
        //     width: '100%',
        //     objectFit: 'contain',
        //   },
        // },
      },
      '& .boxDescription': {
        ...theme.mixins.marginLeft(pxToRem(16)),
        '& .headingInner': {
          wordBreak: 'break-word',
          fontSize: pxToRem(16),
          fontWeight: '700',
          ...theme.palette.common.ellipsis(2),
        },
        // marginLeft: '16px',
        '& .timeLabel': {
          marginTop: pxToRem(8),
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            width: pxToRem(20),
            height: pxToRem(20),
            '& path': {
              fill: '#898C8D',
            },
            '& ellipse': {
              stroke: '#898C8D',
            },
          },
          '& span': {
            fontSize: pxToRem(14),
            lineHeight: '24px',
            fontWeight: '400',
            textTransform: 'uppercase',
            ...theme.mixins.marginLeft(pxToRem(5)),
            color: '#706C6C',
          },
        },
      },
    },
    '& .linkWrapper': {
      paddingBottom: '24px',
      paddingTop: '12px',
      display: 'flex',
      justifyContent: 'space-between',
      // '@media (min-width: 1536px) and (max-height: 870px)': {
      //   flexDirection: 'column'
      // },
      // '& a': {
      //   flex: '0 0 48.9%',
      //   '@media (min-width: 1536px) and (max-height: 870px)': {
      //     marginBottom: '10px'
      //   },
      //   '& .fav-itinerary-btn': {
      //     width: '100%'
      //   },
      // },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },

      '& .fav-itinerary-btn': {
        background: 'transparent',
        border: '1px solid #FF8707',
        [theme.breakpoints.up('lg')]: {
          flex: '0 0 48.9%',
          '[dir="rtl"] &': {
            flex: '0 0 auto',
          },
        },
        [theme.breakpoints.down('md')]: {
          minHeight: pxToRem(48),
        },
        '&.itinerary-btn': {
          background: '#FF8707',
          '& .btn-txt': {
            color: '#fff',
          },
          [theme.breakpoints.up('lg')]: {
            padding: `${pxToRem(8)} ${pxToRem(13.75)} ${pxToRem(8)} ${pxToRem(
              13.75
            )}`,
            // marginRight: '6px'
          },
          [theme.breakpoints.down('md')]: {
            marginBottom: '12px',
            width: '100%',
          },
        },
        '&.create-itinerary-btn': {
          [theme.breakpoints.up('lg')]: {
            padding: `${pxToRem(8)} ${pxToRem(17.25)}`,
            // marginLeft: '6px'
          },
        },

        '& .btn-txt': {
          color: '#FF8707',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
        },
        '& .material-icons': {
          width: '15px',
          '& svg': {
            color: '#FF8707',
            width: '15px',
            height: '12px',
            '[dir="rtl"] &': {
              transform: 'rotate(180Deg)',
            },
          },
        },

        '& .MuiButton-endIcon': {
          marginLeft: '8px !important',
          marginBottom: '5px',
        },
      },

      '& .link': {
        // maxWidth: '194px',
        // width: '100%',
        display: 'block',
        color: '#FF8707',
        cursor: 'pointer',
        ...theme.mixins.marginRight(pxToRem(24)),
        // '&:focus': {
        //   outline: '2px solid #000',
        // },
        '&:last-child': {
          ...theme.mixins.marginRight(pxToRem(0)),
        },
        '[dir="rtl"] &': {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
        '& span': {
          '[lang="zh"] &, [lang="he"] &': {
            fontSize: pxToRem(14),
          },
          fontSize: pxToRem(16),
          fontWeight: '700',
        },
        '& svg': {
          width: pxToRem(15),
          height: pxToRem(12),
          // marginRight: '12px'
          ...theme.mixins.marginRight(pxToRem(12)),
          '[dir="rtl"] &': {
            transform: 'rotate(180Deg)',
            ...theme.mixins.marginRight(pxToRem(0)),
            ...theme.mixins.marginLeft(pxToRem(12)),
          },
        },
        '&.linkAlingment': {
          marginBottom: '0 !important',
        },
        '&:first-child': {
          [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
          },
        },
      },
    },
  },

  '@global': {
    '#favorite-section-popup': {
      zIndex: 1400,
    },
  },
}));
