import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  const { right, left, paddingLR } = theme.mixins;
  return {
    root: {
      display: 'inline-block',
      '& .default-shrink': {
        position: 'absolute',
        transform: 'translate(0, -41.5px) scale(0.9)',
        ...left(pxToRem(-8)),
      },

      '& .react-datepicker-popper': {
        zIndex: 999,
        '& .react-datepicker': {
          borderRadius: borderRadius.b0,
          border: 'none',
          boxShadow: theme.shadows[1],
          width: '100%',
          display: 'flex',

          '& .react-datepicker__navigation': {
            border: 'solid',
            borderWidth: '0 2px 2px 0',
          },
          '& .react-datepicker__navigation--previous': {
            transform: 'rotate(138deg)',
            top: pxToRem(19),
            left: pxToRem(29),
            '[dir="rtl"] &': {
              right: pxToRem(29),
              left: 'inherit',
              transform: 'rotate(314deg)',
            },
          },
          '& .react-datepicker__navigation--next': {
            transform: 'rotate(314deg)',
            top: pxToRem(19),
            right: pxToRem(29),
            '[dir="rtl"] &': {
              left: pxToRem(29),
              right: 'inherit',
              transform: 'rotate(138deg)',
            },
          },
          '& .react-datepicker__triangle': {
            display: 'none',
          },
          '& .react-datepicker__month-container': {
            width: pxToRem(282),
            '& .react-datepicker__day-names, .react-datepicker__week': {
              display: 'flex',
              justifyContent: 'center',
              gap: '5px',
            },
            '& .react-datepicker__month': {
              ...theme.typography.labelRegular,
              background: `${theme.mixins.White()} !important`,

              '& .react-datepicker__day--selecting-range-end, .react-datepicker__day--range-end, .react-datepicker__day--range-start': {
                background: theme.mixins.Black(),
                borderRadius: `${borderRadius.circle}`,
                color: theme.mixins.White(),
              },
              '& .react-datepicker__day--in-selecting-range': {
                background: theme.palette.action.disabledBackground,
                color: theme.mixins.Black(),
                borderRadius: `${borderRadius.b0}`,
                position: 'relative',
                '&:after': {
                  content: `''`,
                  width: '5px',
                  height: '100%',
                  top: '0',
                  ...left('100%'),
                  position: 'absolute',
                  background: theme.palette.action.disabledBackground,
                },
                '&.react-datepicker__day--selecting-range-start': {
                  background: theme.mixins.Grey(),
                  borderRadius: `${borderRadius.circle}`,
                  color: theme.mixins.White(),
                },
                '&.react-datepicker__day--selecting-range-end': {
                  background: theme.mixins.Black(),
                  borderRadius: `${borderRadius.circle}`,
                  color: theme.mixins.White(),
                },
              },
              '& .react-datepicker__day--in-range': {
                background: theme.palette.action.disabledBackground,
                color: theme.mixins.Black(),
                borderRadius: `${borderRadius.b0}`,
                position: 'relative',
                '&:after': {
                  content: `''`,
                  width: '5px',
                  height: '100%',
                  top: '0',
                  ...left('100%'),
                  position: 'absolute',
                  background: theme.palette.action.disabledBackground,
                },
                '&.react-datepicker__day--range-start': {
                  background: theme.mixins.Black(),
                  borderRadius: `${borderRadius.circle}`,
                  color: theme.mixins.White(),
                },
                '&.react-datepicker__day--range-end': {
                  background: theme.mixins.Black(),
                  borderRadius: `${borderRadius.circle}`,
                  color: theme.mixins.White(),
                },
                '&.react-datepicker__day--selecting-range-start': {
                  // background: theme.mixins.Grey(),
                  borderRadius: `${borderRadius.circle}`,
                  color: theme.mixins.White(),
                },
              },
              '& .react-datepicker__day': {
                height: pxToRem(32),
                width: pxToRem(32),
                // borderRadius: borderRadius.circle,
                paddingTop: 6,
                paddingBottom: 6,
                margin: '3px 0px 3px 0px',
                lineHeight: pxToRem(22),

                '&:hover': {
                  borderRadius: borderRadius.circle,
                },

                '&.react-datepicker__day--keyboard-selected': {
                  color: theme.mixins.Black(),
                  '&:not(.react-datepicker__day--in-selecting-range)': {
                    backgroundColor: theme.mixins.White(),
                  },

                  '&.react-datepicker__day--range-end, &.react-datepicker__day--range-start': {
                    background: theme.mixins.Black(),
                    borderRadius: `${borderRadius.circle}`,
                    color: theme.mixins.White(),
                  },
                },
              },
              '& .react-datepicker__day--today': {

                '&:hover': {
                  backgroundColor: theme.mixins.Grey(200),
                  color: theme.mixins.Black(),
                  border: '0px'
                },
                '&.react-datepicker__day--range-end, &.react-datepicker__day--range-start': {
                  background: theme.mixins.Grey(),
                  borderRadius: `${borderRadius.circle} !important`,
                  color: theme.mixins.White(),
                },
              },
              '& .react-datepicker__day--selected': {
                background: theme.mixins.Grey(),
                color: theme.mixins.White(),
                borderRadius: '50%',
              },
              '& .react-datepicker__day--outside-month, & .react-datepicker__day--outside-month.react-datepicker__day--range-end': {
                backgroundColor: `${theme.mixins.White()} !important`,
                borderRadius: '0',
                color: `${theme.palette.action.disabled} !important`,
                pointerEvents: 'none',
                position: 'relative',
                '&:after': {
                  backgroundColor: `${theme.mixins.White()} !important`,
                },
              },
            },
            '& .react-datepicker__header': {
              background: theme.mixins.White(),
              borderBottom: 'none',

              '& .react-datepicker__day-names .react-datepicker__day-name': {
                ...theme.typography.labelRegular,
                color: theme.palette.text.datePicker,
                marginTop: pxToRem(10),
                margin: 4,
              },
              '& .react-datepicker__current-month': {
                ...theme.typography.body2,
                marginTop: 5,
              },
            },
            '& .react-datepicker__day-name, .react-datepicker__day': {
              margin: '0.4rem',
            },
          },
        },
      },
      '& .calendarIcon': {
        position: 'absolute',
        width: pxToRem(16),
        height: pxToRem(16),
        top: pxToRem(16),
        ...right(pxToRem(20)),
      },

      // fix bgcolor hover issues.
      '& .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text': {
        '&:not(.react-datepicker__day--disabled):hover': {
          background: theme.mixins.Grey() + ' !important',
          color: theme.mixins.White(),
        },
      },
      '& .date-selected .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__day--selecting-range-start)': {
        background: theme.mixins.White(),
        '&:after': {
          display: 'none',
        },
      },
      '& .date-selected .react-datepicker-popper .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):after': {
        display: 'none',
      },
    },
    datePicker: {
      width: '100%',
      height: pxToRem(48),
      paddingLeft: pxToRem(16),
      border: theme.borders.fieldBorder,
      borderRadius: borderRadius.b0,

      '&:hover, &:focus': {
        background: theme.palette.action.disabledBackground,
        backgroundColor: 'inherit',
      },
    },
    CalendarWrapper: {
      position: 'relative',
      marginTop: pxToRem(46),
      width: '100%',

      '& svg': {
        visibility: 'hidden',
      },
      '& .react-datepicker-wrapper': {
        display: 'block',

        '& .react-datepicker__input-container': {
          visibility: 'hidden',
        },
      },
    },
    rangeCalendar: {
      display: 'flex',
      position: 'relative',
      minHeight: '94px'
    },
    rangeCalendarPicker: {
      zIndex: '10',
      position: 'absolute',
      width: '100%',
      cursor: 'pointer',
      ...left(0),
      top: pxToRem(46),
      height: pxToRem(48),
      paddingTop: pxToRem(13),
      ...paddingLR(pxToRem(16), 0),
      backgroundColor: theme.mixins.White(),
      border: theme.borders.fieldBorder,
      borderRadius: borderRadius.b3,
      '&:hover': {
        background: theme.mixins.Black(10),
      },
    },
    labelFilled: {
      position: 'absolute',
      transform: 'translate(0, -41.5px) scale(0.9)',
      ...left(pxToRem(-8)),
      '& p': {
        display: 'inline-block',
      },
    },
    endDate: {
      position: 'absolute',
      ...left(0),
    },
    errorMsg: {
      color: theme.palette.text.error,
      marginTop: pxToRem(8),
    },
    customPlaceholder: {
      paddingRight: pxToRem(36),
      color: theme.palette.text.tertiary,
      ...theme.palette.common.ellipsis(1),
    },
  };
});

export default useStyles;
