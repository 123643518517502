import React, { useState, useCallback } from 'react';
import Typography from '../../atoms/Typography';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FavoriteIcon from '../FavoriteIcon';
import FavoriteList from './FavoriteList'
import clsx from 'clsx';
import useStyles from './style';
import ButtonMUI from '../../atoms/Button';
import { canUseDOM } from '../../../utils/canUseDOM';
import Link from '../../../components/atoms/Link';
import { getLocalStorage } from './../../../../utils/localStorage';
import ItineraryBuilderList from './ItineraryBuilderList';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import ItineraryBuilder from '../../../containers/common/ItineraryBuilder';
import { useDispatch } from 'react-redux';
import { setFavoriteSectionItineraryAdded, toggleItineraryModal } from '../../../containers/common/ItineraryBuilderMap/redux/actions';

const FavoriteSection = props => {
  const { favoriteFields, favoritesList, favoritesCount, isMobile, googlePlacesAPI, language, itineraryPreviewLink, closeMobileMenu = () => { } } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(undefined);
  const [hasFavorite, setHasFavorite] = React.useState(false);
  const [isFavClicked, setIsFavClicked] = React.useState(false);
  const [modalPop, setmodalPop] = useState(false)
  let itineraryList = JSON.parse(getLocalStorage('itineraryList') || '[]')

  // set fav box bot bar height into css
  const favBoxBotBarRef = useCallback(node => {
    if (node !== null) {
      document.documentElement.style.setProperty('--favBoxBotBar',
        `${node.clientHeight}px`);
    }
  }, []);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'favorite-section-popup' : undefined;

  const favoriteClickHandler = (event) => {
    handleClick(event)
    setIsFavClicked(!isFavClicked)
  };


  const handleClose = () => {
    setAnchorEl(false);
    setIsFavClicked(false)
  };

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'arrowIcon':
        return (
          <svg className={`${customClass} customSVG`} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.64 0L14.4 6L8.64 12L7.82784 11.154L12.1939 6.6H0V5.4H12.1939L7.82784 0.846L8.64 0Z" fill={fill} />
          </svg>
        )
        break;
      default:
        return <></>
    }
  }

  const createItineraryHandler = () => {
    if (canUseDOM && window.location.pathname !== itineraryPreviewLink?.value?.href) {
      const redirectURL = `${window.location.origin}${itineraryPreviewLink?.value?.href}`;
      window.location.href = redirectURL;
    } else {
      setAnchorEl(false);
      setmodalPop(true)
      setIsFavClicked(false)
      if (isMobile) {
        closeMobileMenu(false)
        dispatch(toggleItineraryModal(true))
      }
    }
  };

  const renderItineraryList = () => {
    const CTA = favoriteFields.CTA;
    return (
      <div className={`${classes.itineraryList} ${favoritesCount === 0 ? 'itineraryListAlign' : ''}`}>
        {itineraryList?.length === 0 ?
          <Typography className="mainHead">{getDictionaryText('CustomJourney')}</Typography>
          : <Typography className='fav-itinerary-title' variant="body1Bold22" component="span" >
            {getDictionaryText('MyItineraries')}
          </Typography>}

        <ItineraryBuilderList
          itineraryList={itineraryList}
          redirectLink={itineraryPreviewLink}
          {...props}
        />
        <div className='linkWrapper'>
          {itineraryList && itineraryList?.length > 0 ? (
            // <Link tabIndex={0} role="button" customHref={CTA?.value.href} isCustom={true} className="link"> {createSVG('arrowIcon', '#FF8707', '')}
            // <Typography variant="body2Bold" component="span" >
            //   {getDictionaryText('ViewItinerary')}
            // </Typography></Link>
            <ButtonMUI
              type="button"
              buttonType="form"
              size="small"
              tabIndex={0}
              field={{ value: { href: CTA?.value.href } }}
              // disabled={favoritesCount < 1}
              hasBorder={false}
              className="fav-itinerary-btn itinerary-btn"
              endIconSvg={createSVG('arrowIcon', '#FFF', '')}
            >
              {getDictionaryText('MyItineraries')} ({itineraryList?.length ?? 0})
            </ButtonMUI>
          ) : null}

          <ButtonMUI
            type="button"
            buttonType="form"
            size="small"
            tabIndex={0}
            // field={{ value: { href: CTA?.value.href } }}
            // disabled={favoritesCount < 1}
            hasBorder={false}
            className="fav-itinerary-btn create-itinerary-btn"
            onClick={(e) => {
              e.preventDefault()
              createItineraryHandler()
            }}
            endIconSvg={createSVG('arrowIcon', '#FF8707', '')}
          >
            {getDictionaryText('Create')}
          </ButtonMUI>

          {/* <Typography variant="body2Bold" tabIndex={0} isCustom={true} className={`link ${itineraryList?.length === 0 ? 'linkAlingment' : ''}`} onKeyDown={(event) => {
            if (event.key === 'Enter') {
              createItineraryHandler()
            }
          }}> {createSVG('arrowIcon', '#FF8707', '')} <Typography variant="body2Bold"
            onClick={(e) => {
              e.preventDefault()
              createItineraryHandler()
            }}

            component="span" >{getDictionaryText('Create')}</Typography></Typography> */}

        </div>
      </div>
    )
  }

  const renderFavoriteList = () => {
    const CTA = favoriteFields.CTA;
    return (
      <div className='listInner' style={{ height: '100%' }}>
        <div className={`${classes.favoriteListWrapper} header-fav-tags`} tabIndex={0}>
          {/* {favoritesCount < 1 && */}
          <div className={classes.emptyListWrap} tabIndex={0}>
            {!isMobile &&
              <Typography
                variant={'body1Bold22'}
                component={'h5'}
                animateSettings={{
                  willAnimate: false,
                }}
              >
                {favoriteFields?.Title.value}
              </Typography>
            }

            {isMobile &&
              <div>
                <Typography
                  variant={'h2'}
                  component={'h2'}
                  animateSettings={{
                    willAnimate: false,
                  }}
                >
                  {favoriteFields?.Title.value}
                </Typography>
              </div>
            }
            {favoritesCount < 1 &&
              <Typography
                variant={'body2'}
                component={'span'}
                classes={{ root: classes.emptyListSpan }}
              >
                {/* Items you mark as favorite will appear here, you currently have no favorites saved to your list! */}
                {favoriteFields?.Message.value}
              </Typography>
            }
          </div>
          {/* } */}
          {favoritesCount > 0 &&
            <FavoriteList
              favoritesList={favoritesList}
              googlePlacesAPI={googlePlacesAPI}
              language={language}
              {...props}
            />
          }
          <div className={`${classes.listFooter} fav-box-bot-bar`} ref={favBoxBotBarRef}>

            <ButtonMUI
              type="button"
              buttonType="form"
              size="small"
              tabIndex={0}
              field={{ value: { href: CTA?.value.href } }}
              disabled={favoritesCount < 1}
              hasBorder={false}
              className="favCta"
            >
              <Typography
                variant=""
                component="span"
                color="inherit"
                className="primaryCTA"
              >
                {CTA?.value?.text} ({favoritesCount})
              </Typography>
            </ButtonMUI>
            {/* {isMobile
              ?
              (<Link isDisabled={favoritesCount < 1} customHref={CTA?.value.href} isCustom={true} className="link"> {createSVG('arrowIcon', `${favoritesCount < 1 ? '#AAAAAA' : '#FF8707'}`, '')} <Typography variant="body2Bold"
                component="span" >{CTA?.value?.text} ({favoritesCount})</Typography></Link>)
              :
              ''} */}
          </div>
          {!isMobile && renderItineraryList()}
        </div>
        {isMobile && renderItineraryList()}
      </div>
    )
  }

  const handleItineraryBuilderSubmitBtn = (formData) => {
    dispatch(setFavoriteSectionItineraryAdded(formData));
    if (isMobile) {
      closeMobileMenu(false)
    }
  }

  return (
    <>
      {!isMobile &&
        <div className="favorite-section-wrapper">
          <React.Fragment>
            <div className={classes.favRoot} aria-describedby={id}>
              <FavoriteIcon
                ariaExpanded={anchorEl === undefined ? undefined : open ? true : false}
                favoritesCount={favoritesCount}
                onClickHandler={(e) => favoriteClickHandler(e)}
                iconOnly={true}
                aria-label="favorite button"
                edge="start"
                hasFavorite={hasFavorite}
                isFavClicked={isFavClicked}
                isMobile={isMobile}
                className={clsx(
                  classes.favoriteIcon,
                )}
              />
            </div>
          </React.Fragment>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            disablePortal={true}
            placement="bottom-end"
            transition={true}
          >
            <ClickAwayListener onClickAway={handleClose}>
              {renderFavoriteList()}
            </ClickAwayListener>
          </Popper>
        </div>
      }

      {isMobile && <div className='favourWrapper'>
        {renderFavoriteList()}
      </div>}
      <ItineraryBuilder
        navigateOnSubmit={true}
        customClass={'createItinerary'}
        open={modalPop}
        handleExit={() => { setmodalPop(false) }}
        itineraryList={itineraryList}
        isCreateItinerary={true}
        handleSubmitBtn={handleItineraryBuilderSubmitBtn}
      />
    </>
  );
};

FavoriteSection.prototype = {

};

FavoriteSection.defaultProps = {

};

export default FavoriteSection;