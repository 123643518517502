import { getDictionaryText } from './getDictionaryText';

// This will return true if user has added min required number of files
export const shouldDisableUploadButton = (documents) => {
  if (documents && documents.length >= 5) {
    return true;
  } else if (documents && documents.length) {
    let totalFilesSize = 0;
    const maxFileUploadSize =
      getDictionaryText('maxFileUploadSize') &&
      parseInt(getDictionaryText('maxFileUploadSize'));
    documents.forEach(file => {
      totalFilesSize += file.size;
    });
    if (totalFilesSize > maxFileUploadSize) {
      return true;
    }
  } else {
    return false;
  }
}