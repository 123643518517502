import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  const { paddingLR, left, marginLR } = theme.mixins;
  return {
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingTop: pxToRem(96),
        ...paddingLR(`${pxToRem(92)}`, 0),
        paddingBottom: pxToRem(88),
        backgroundColor: props =>
          get(props, 'fields.backgroundColor.fields.colorCode.value', ''),
      },
      '& input[type = number]::-webkit-inner-spin-button, & input[type = number]::-webkit-outer-spin-button': {
        '-webkitAappearance': 'none',
        '-mozAppearance': 'none',
        appearance: 'none',
        margin: 0,
      },
      'input[type=number]': {
        '-mozAppearance': 'textfield',
      },

      '& .rfp-form-wrapper > div:first-child': {
        '& .formHeading': {
          '&:first-child': {
            marginTop: 0,
          },
        },
      },
    },
    formContainer: {
      backgroundColor: theme.mixins.White(),
      ...paddingLR(`${pxToRem(24)}`, `${pxToRem(24)}`),
      paddingBottom: pxToRem(48),
      paddingTop: pxToRem(48),
      [theme.breakpoints.up('lg')]: {
        paddingTop: pxToRem(88),
        paddingBottom: pxToRem(88),
        ...paddingLR(`${pxToRem(60)}`, `${pxToRem(60)}`),
        width: '51%',
      },
    },
    formHeading: {
      marginBottom: pxToRem(40),
      marginTop: pxToRem(12),
      [theme.breakpoints.up('lg')]: {
        marginTop: pxToRem(28),
        marginBottom: pxToRem(32),
      },
    },
    captchaWrapper: {
      marginBottom: pxToRem(32),

      [theme.breakpoints.up('lg')]: {
        ...marginLR(pxToRem(8), pxToRem(8)),
      },
    },
    formWrapper: {
      '& .MuiFormControl-root, .MuiFormControl-root.MuiTextField-root': {
        marginBottom: pxToRem(36),
        [theme.breakpoints.up('lg')]: {
          marginBottom: pxToRem(32),
        },
      },
      '& .MuiFormControl-root': {
        marginBottom: pxToRem(36),
        marginTop: 0,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          margin: theme.spacing(1),
          width: 'calc(50% - 16px)',
          marginBottom: pxToRem(32),
          marginTop: 0,

          '&.fullWidth': {
            width: 'calc(100% - 16px)',
          },
        },
        '& .MuiFormControlLabel-root': {
          alignItems: 'flex-start',
          '& .MuiCheckbox-root': {
            paddingTop: 0,
          },
        },
        '& .calendarWrapper': {
          marginTop: pxToRem(30),
        },
        '& .rangeCalendarPicker': {
          top: pxToRem(30),
          '& label': {
            ...left(pxToRem(-5)),
            transform: `translate(0, ${pxToRem(-41.5)}) scale(0.9)`,
          },
        },
        '& .MuiFormGroup-root': {
          '& .MuiFormControlLabel-label': {
            lineHeight: fontSize.s24Rem,
          },
          '& .MuiFormControlLabel-root': {
            alignItems: 'center',
          },
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'row',
          },
        },
        '& .MuiFormLabel-root': {
          fontSize: 'inherit',
          fontFamily: theme.custom.fontFamily.primaryRegular,
        },
        '&.boldText .MuiFormLabel-root': {
          fontSize: 'inherit',
          fontFamily: theme.custom.fontFamily.primaryBold,
          marginBottom: pxToRem(10),
        },

        '&.selectMenu': {
          '& .MuiFormLabel-root': {
            position: 'relative',
            transform: `translate(0, ${pxToRem(-25)}) scale(.9)`,
            '& .MuiTypography-body2Bold': {
              fontFamily: theme.custom.fontFamily.primaryRegular,
            },
          },
          '& .MuiInput-formControl': {
            marginTop: pxToRem(0),
            '& .MuiSelect-select': {
              fontFamily: theme.custom.fontFamily.body2,
              paddingBottom: pxToRem(10),
            },
          },
        },
      },
      '& button.Mui-disabled': {
        background: theme.mixins.Black(300),
      },
    },
    formTitle: {
      marginBottom: pxToRem(18),
      [theme.breakpoints.up('lg')]: {
        ...marginLR(pxToRem(8), pxToRem(8)),
        marginBottom: pxToRem(12),
      },
    },
    uploadError: {
      [theme.breakpoints.up('lg')]: {
        ...marginLR(pxToRem(8), pxToRem(8)),
      },

      color: theme.mixins.AbuDhabiOrange(),
      marginTop: pxToRem(16),
    },
    messageWrapper: {
      position: 'relative',
      // height: '100%',
      height: `calc(100vh - ${pxToRem(156)})`,
      [theme.breakpoints.up('lg')]: {
        height: `calc(100vh - ${pxToRem(156)})`,
      },

      '& .formSubmissionMessage': {
        '& h2, & h3, & h4, & h5, & h6': {
          margin: 0,
          marginBottom: pxToRem(16),
        },
        '& p': {
          margin: 0,
          marginBottom: pxToRem(24),
          ...theme.typography.body2,
        },
        '& a': {
          fontSize: fontSize.s16Rem,
          fontFamily: theme.custom.fontFamily.primaryBold,
          textDecoration: 'none',
        },
      },
    },
    submitButton: {
      [theme.breakpoints.up('lg')]: {
        ...marginLR(pxToRem(8), pxToRem(8)),
      },
      '& .MuiButton-label': {
        fontFamily: theme.custom.fontFamily.primaryBold,
      },
    },
    submissionMessage: {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      // we don't need mixin here because message wrapper
      // will always be 50% from left
      left: '50%',
      width: '60%',
      transform: 'translate(-50%, -50%)',
      '& svg': {
        marginBottom: theme.spacing(2),
      },
    },
  };
});

export default useStyles;
