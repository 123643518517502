import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import DctSvgIcon from '../Svg';
import useStyles from './style';
import clsx from 'clsx';
import FavoriteContext from './../../../../context/favorite/FavoriteContext';
import { pushAnaylyticsData } from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { getLocalStorage } from './../../../../utils/localStorage';
import Typography from '../Typography';
import { canUseDOM } from '../../../utils/canUseDOM';
import { EVENT_TYPE } from '../../../../constants';
import CustomToast from '../../atoms/Toast';
import ItineraryBuilder from '../../../containers/common/ItineraryBuilder';
import { isItineraryExist } from './../../../../utils/utility';
import { showToast } from '../../../utils/showToast';
import { toast } from 'react-toastify';
import { useTheme } from '@material-ui/core/styles';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { useMediaQuery } from '@material-ui/core';

export default function Favorite({
  itemUniqueKey,
  isShowFavorite,
  componentName,
  dictionary,
  isDetailPresent,
  contentType,
  ariaLabel = '',
  className = '',
  showToastBtn = true,
  favCallback = () => {},
}) {
  const { isFavorite, addToFavorite, removeFromFavorite } = useContext(
    FavoriteContext
  );
  const classes = useStyles({ contentType });
  let favData = JSON.parse(getLocalStorage('favorites') || '[]');
  const isFav = isFavorite(itemUniqueKey);
  const isFromFavMenu = componentName === 'favoriteNav';
  const isFromMapComponent = componentName === 'mapComponent';
  const isFromStickyTitle = componentName === 'StickyTitle';
  const isFromSlider = componentName === 'heroCarousel';
  const [favTaxWidth, setFavTaxWidth] = useState('0px');
  const [windowResize, setWindowResize] = useState(0);
  const [modalPop, setModalPop] = useState(false);
  let itineraryList = isItineraryExist();
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  let favTxtWidth1 = 0,
    favTxtWidth2 = 0;

  const favTxtRef1 = useCallback(
    node => {
      if (node) {
        favTxtWidth1 = node.clientWidth;
        setFavWidth();
      }
    },
    [windowResize]
  );
  const favTxtRef2 = useCallback(
    node => {
      if (node) {
        favTxtWidth2 = node.clientWidth;
        setFavWidth();
      }
    },
    [windowResize]
  );

  const handleResize = () => {
    if (canUseDOM) {
      setWindowResize(window.innerWidth);
    }
  };

  useEffect(() => {
    if (canUseDOM) {
      window.addEventListener('resize', handleResize);

      return _ => {
        window.removeEventListener('resize', handleResize);
      };
    }
  });

  const ariaLabelText = useMemo(() => {
    return isFav
      ? getDictionaryText('RemoveFromFavourites')?.replace('{item}', ariaLabel)
      : getDictionaryText('AddToFavourites')?.replace('{item}', ariaLabel) ||
          '';
  }, [ariaLabel, isFav]);

  const setFavWidth = () => {
    let maxWidth = Math.max(favTxtWidth1, favTxtWidth2);
    setFavTaxWidth(maxWidth + 'px');
  };

  const trackAnalyticsInfo = isAdding => {
    let eventObject = analyticsData.pageBodyInteraction.removeItemToFavorite;
    if (isAdding) {
      eventObject = analyticsData.pageBodyInteraction.addItemToFavorite;
    }
    const obj = {
      ...eventObject,
      label: itemUniqueKey,
    };
    pushAnaylyticsData(obj);
  };

  const handleToggleAddToFavorite = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isFavorite(itemUniqueKey)) {
      removeFromFavorite(itemUniqueKey);
      trackAnalyticsInfo(false);
      if (componentName == 'FavoriteSubLayout') {
        isShowFavorite && isShowFavorite(itemUniqueKey);
      }
    } else if (!isFromFavMenu) {
      // Favorite limit reached!
      if (favData.length >= 1000) {
        return false;
      }
      addToFavorite(itemUniqueKey);
      trackAnalyticsInfo(true);
      showToast(
        <CustomToast
          showLinkLabel={showToastBtn}
          truncateLimit={(isRTL && isMobile && 15) || 70}
          handleClose={() => {
            toast.dismiss();
          }}
          onClick={() => {
            setModalPop(true);
            toast.dismiss();
          }}
          textTitle={getDictionaryText('SavedToFavorites')}
        />,
        isRTL
      );
    }
    if (favCallback && typeof favCallback === 'function')
      favCallback(isFavorite(itemUniqueKey) ? false : true);
  };

  if (!itemUniqueKey) return <></>;

  return (
    <div>
      <div
        className={clsx(
          {
            stickyFavoriteIcon: isFromStickyTitle,
            [classes.heartWrap]: !isFromMapComponent,
            [classes.absoluteHeartWrap]: isFromMapComponent,
            [classes.addToFavWrapperDesc]:
              isFromStickyTitle && isDetailPresent === 'contacts-section',
            [classes.addToFavWrapperDescMin]:
              isFromStickyTitle && isDetailPresent !== 'contacts-section',
          },
          className
        )}
        aria-label={ariaLabelText}
        role="button"
        tabIndex={0}
        onClick={e => handleToggleAddToFavorite(e)}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            handleToggleAddToFavorite(event);
          }
        }}
      >
        <div
          className={clsx({
            [classes.addToFavIconWrapDesc]: isFromStickyTitle,
            [classes.heartWrap]: !isFromMapComponent,
            [classes.circularHeartWrap]: isFromMapComponent,
          })}
        >
          <span
            className={clsx(
              isFromFavMenu
                ? classes.heartNavIcon
                : isFromMapComponent
                ? classes.mapHeartIcon
                : isFromStickyTitle
                ? classes.descHeartIcon
                : isFromSlider
                ? classes.sliderHeartIcon
                : classes.heartIcon,
              'heartIcon',
              isFav && `${classes.heartIconSelected} selected`
            )}
          >
            <DctSvgIcon
              name={'FavoriteIcon'}
              stroked={!isFromFavMenu}
              filled={isFav}
            />
          </span>
        </div>
        {isFromStickyTitle &&
          dictionary &&
          isDetailPresent === 'contacts-section' && (
            <>
              <div
                className="addToFavTextInStickyTitle"
                style={{
                  minWidth: favTaxWidth,
                }}
              >
                <div
                  className={clsx(classes.addToFavText, {
                    [classes.showFavText]: isFav,
                  })}
                  ref={favTxtRef1}
                >
                  <Typography variant={'body2'} component="span">
                    {dictionary('FavouriteDetailAdded')}
                  </Typography>
                </div>
                <div
                  className={clsx(classes.addToFavText, {
                    [classes.showFavText]: !isFav,
                  })}
                  ref={favTxtRef2}
                >
                  <Typography variant={'body2'} component="span">
                    {dictionary('FavouriteDetailAdd')}
                  </Typography>
                </div>
              </div>
            </>
          )}
      </div>
      <ItineraryBuilder
        navigateOnSubmit={true}
        itemUniqueKey={itemUniqueKey}
        itineraryExist={itineraryList}
        customClass={itineraryList ? 'addItenary' : 'createItinerary'}
        open={modalPop}
        handleExit={() => {
          setModalPop(false);
        }}
        setIsOpen={e => {
          setModalPop(e);
        }}
      />
    </div>
  );
}
