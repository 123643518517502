import React, { useState } from 'react';
import Typography from '../../../atoms/Typography';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import CustomLink from '../../../atoms/Link';
import PropTypes, { object } from 'prop-types';
import CategoryTag from '../../../atoms/CategoryTag';
import CustomAvatar from '../../../atoms/Avatar';
import Box from '../../../molecules/Box';
import Picture from '../../../atoms/Picture';
import useStyles from '../style';
import useEventStyles from './style';
import { getProcessedEventDate } from '../../../../utils/dateTimeFunctions/dateTimeFunctions';
import aspectRatios from '../../../../utils/AspectRatios';
import Favorite from '../../../atoms/Favorite';
import { useTheme } from '@material-ui/core/styles';
import TagsWrapper from '../TagsWrapper';

const EventCard = ({
  title,
  desktopImage,
  mobileImage,
  ctaLink,
  itemUniqueKey,
  componentName,
  imageCtaUrl,
  cardType,
  isShowFavorite,
  showFavoriteIcon,
  other,
}) => {
  const {
    startDate,
    endDate,
    locationTitle,
    region,
    isEventListing,
    eventType,
    // peopleInterested, // This will come in vad-1.2
    openTime,
    closeTime,
    umbrellaEventCount,
    pageContentType,
    language,
    forcedRatio,
    lazyLoading = false,
  } = other;
  let umbrellaECount = umbrellaEventCount;
  if (umbrellaEventCount > 0 && umbrellaEventCount < 9) {
    umbrellaECount = `0${umbrellaEventCount}`;
  } else if (umbrellaEventCount === 0) {
    umbrellaECount = '';
  }
  const theme = useTheme();
  const eventStyle = useEventStyles({ umbrellaECount, pageContentType }) || {};
  const [show, setShow] = useState('');

  let categoryTags = [...ctaLink] || [];
  const classes = useStyles({ cardType });
  if (eventType && eventType.isPaid) {
    if (categoryTags && categoryTags.length > 0) {
      categoryTags[1] = eventType.isPaid;
    } else {
      categoryTags[0] = eventType.isPaid;
    }
  }
  if (Array.isArray(other.eventCategories) && other.eventCategories.length > 0) {
    categoryTags = categoryTags.concat(other.eventCategories);
  }

  return (
    <React.Fragment>
      {showFavoriteIcon &&
        <Favorite ariaLabel={title} itemUniqueKey={itemUniqueKey} isShowFavorite={isShowFavorite} componentName={componentName} />
      }
      <Box
        // link={{ value: { href: imageCtaUrl } }}
        className={eventStyle.linkRoot}
      >
        <Box className={`${classes.imageWrapper} imageWrap`} mb={4}>
          <Picture
            media={{
              image: desktopImage,
              mobileImage: mobileImage,
              disableLazyLoad: !lazyLoading,
              aspectRatio: aspectRatios['3x2-card'],
              forcedRatio,
            }}
          // alternateAltText={title && title.value}
          />
          {
            <Box className={eventStyle.categoryOnImageWrapper}>
              {eventType && eventType.featured && (
                <Typography
                  variant="labelBold"
                  component="span"
                  className={eventStyle.categoryOnImage}
                >
                  {eventType.featured}
                </Typography>
              )}
            </Box>
          }
          {umbrellaECount && (
            <Box className={eventStyle.umbrellaEventCountWrapper}>
              <CustomAvatar className={eventStyle.umbrellaEventCount}>
                <Typography component="span" variant="body2Bold">
                  <Text field={{ value: umbrellaECount }} />
                </Typography>
                <Typography component="p" variant="body2Bold">
                  <Text field={{ value: 'Events' }} />
                </Typography>
              </CustomAvatar>
            </Box>
          )}
        </Box>
        <Box className={eventStyle.collageType1}>
          <Box mr={1} className={eventStyle.collageType1subHeading}>
            <Box className={eventStyle.eventDateWrapper}>
              {[
                getProcessedEventDate(startDate, language),
                getProcessedEventDate(endDate, language),
              ].map((item, index) => {
                return (
                  <Box className={eventStyle.collageType1Card} key={index}>
                    <Typography
                      component="div"
                      variant="body1Bold"
                      className={`${eventStyle.eventDate} eventDate`}
                    >
                      {item.day}
                    </Typography>
                    <Typography
                      component="div"
                      variant="labelRegular"
                      className={eventStyle.eventMonth}
                    >
                      {item.month}
                    </Typography>
                    <Typography component="div" variant="labelRegular">
                      {item.year}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            pb={{ xs: 3 }}
            px={{ lg: 3 }}
            pt="0"
            className={`${classes.cardContent} ${eventStyle.cardDescription}`}
          >
            <Box className='card-description'>
              <CustomLink
                link={{ value: { href: imageCtaUrl } }}
                className={'customLink'}
                srText={title}
              >
                <Typography variant="cardTitle2" component="h3">
                  <Text field={{ value: title }} />
                </Typography>
              </CustomLink>
              {!isEventListing && (
                <Typography
                  variant="body3"
                  color="textSecondary"
                  className={eventStyle.locationItem}
                >
                  <Text field={{ value: `${locationTitle} - ${region}` }} />
                </Typography>
              )}
              {isEventListing && region && locationTitle && (
                <Typography
                  variant="body3"
                  component="div"
                  classes={{
                    root: `${classes.location} ${eventStyle.timings}`,
                  }}
                >
                  <Box component="span" display="inline-flex">
                    <Text field={{ value: `${locationTitle} - ${region}` }} />
                  </Box>
                </Typography>
              )}
              {openTime && closeTime && (
                <Typography
                  variant="labelBold"
                  component="div"
                  className={eventStyle.timings}
                >
                  <Box component="span" display="inline-flex">
                    {`${openTime}`}
                  </Box>
                  <Box component="span" display="inline-flex">
                    {' - '}
                  </Box>
                  <Box component="span" display="inline-flex">
                    {`${closeTime}`}
                  </Box>
                </Typography>
              )}

              {/* {isEventListing && peopleInterested && (    // This will come in VAD-1.2
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={eventStyle.locationItem}
                >
                  <IconWithText
                    iconType="FavoriteBorderIcon"
                    avatarClassName="location-icon favourite-icon"
                    iconWithCircle={false}
                  >
                    <Text field={{ value: peopleInterested }} />
                  </IconWithText>
                </Typography>
              )} */}
            </Box>
            {
              categoryTags ?
                <TagsWrapper
                  ctaLink={categoryTags}
                  itemUniqueKey={itemUniqueKey}
                  className={eventStyle.CategoryTag}
                  borderStyle={theme.borders.seaGreenBorder}
                  colorStyle={theme.mixins.SeaGreen()}
                />
                : null
            }
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

EventCard.propTypes = {
  title: PropTypes.string,
  desktopImage: PropTypes.object,
  mobileImage: PropTypes.object,
  ctaLink: PropTypes.array,
  imageCtaUrl: PropTypes.string,
  cardType: PropTypes.string,
};

EventCard.defaultProps = {
  title: '',
  desktopImage: {},
  mobileImage: {},
  ctaLink: [],
  imageCtaUrl: '',
  cardType: "",
};

export default withSitecoreContext()(withNamespaces()(EventCard));
