import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import Link from '../Link';
import Typography from '../Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DctSvgIcon from '../Svg';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';

import useStyles from './style';

const LanguageSelector = props => {
  const classes = useStyles();
  const [host, setHost] = useState(null);
  const { optionList, selectedLanguage, handleChangeEvent } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [languageSelector, setLangSelector] = React.useState(false);

  const lang = {
    'En': 'English',
    'Ch': 'Chinese',
    'It': 'Italian',
    'العربية': 'Arabic',
    'Ge': 'German',
    'He': 'Herbew',
    'Fr': 'French',
    'Ru': 'Russian',
  }

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setLangSelector(!languageSelector)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'language-selector-popup' : undefined;

  const optionLength = optionList && optionList.length;

  const handleClose = () => {
    setAnchorEl(false);
    setLangSelector(false)
  };

  useEffect(() => {
    setHost(window.location.origin.toString());
  });

  return (
    <div className="language-selector-wrapper">
      <div
        aria-label={`${lang[selectedLanguage]} language selected.${languageSelector ? ' Change language' : ''}`}
        aria-describedby={id}
        className={clsx(
          classes.languageSelectorBtn, open && languageSelector ? 'language-red-icon' : ''
        )}
        role='button'
        tabIndex={'0'}
        aria-expanded={open ? true : false}
        onClick={handleClick}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleClick(event);
          }
        }}
      >
        <Typography variant="body3Bold" component="span">
          <DctSvgIcon name="Language" />
          {selectedLanguage}
        </Typography>
      </div>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition={true}
        tabIndex={1}
        container={() => document.getElementById('popperContainer')}
        role='button'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.languageSelector} tabIndex={1} role='button'>
            <List component="ul" role="list">
              {optionLength > 0 &&
                optionList.slice(0, 4).map(option => {
                  return (
                    <ListItem
                      key={option.value}
                      classes={{
                        root: option && option.selected ? 'selected' : '',
                      }}
                    >
                      <Link
                        tabIndex={0}
                        customHref={host + '/' + option.value}
                        isCustom={true}
                        onClick={handleChangeEvent(option && option.value, option && option.code)}
                      >
                        <Typography variant="subTitleBold" component="span">
                          {option && option.nativeName}
                        </Typography>
                        {option && option.selected && (
                          <DctSvgIcon name="CheckIcon" />
                        )}
                      </Link>
                    </ListItem>
                  );
                })}
            </List>
            {optionLength > 4 && (
              <List component="ul" role="list">
                {optionList.slice(4).map(option => {
                  return (
                    <ListItem
                      key={option.value}
                      {...option}
                      classes={{
                        root: option && option.selected ? 'selected' : '',
                      }}
                    >
                      <Link
                        tabIndex={0}
                        customHref={host + '/' + option.value}
                        isCustom={true}
                        onClick={handleChangeEvent(option && option.value, option && option.code)}
                      >
                        <Typography variant="subTitleBold" component="span">
                          {option && option.nativeName}
                        </Typography>
                        {option && option.selected && (
                          <DctSvgIcon name="CheckIcon" />
                        )}
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
      <div id="popperContainer" className={classes.popupBox} tabIndex={0}></div>
    </div>
  );
};

LanguageSelector.prototype = {
  optionList: PropType.shape([
    {
      value: PropType.string,
      label: PropType.string,
    },
  ]).isRequired,
  selectedLanguage: PropType.string.isRequired,
  handleChangeEvent: PropType.func,
};

LanguageSelector.defaultProps = {
  optionList: [
    {
      value: '',
      label: '',
    },
  ],
  selectedLanguage: '',
  handleChangeEvent: () => { },
};

export default LanguageSelector;
