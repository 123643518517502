import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#fffff',
    maxWidth: '614px',
    width: '100%',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    transition: 'ease 1s all',
    // position: 'absolute',
    // top: 0,
    // left: '-614px',
    // '&.active': {
    //   left: '72px',
    //   [theme.breakpoints.down('sm')]: {
    //     left: '12px',
    //   },
    // },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      // maxWidth: '351px',
      // margin: '0 auto',
      // left: "-351px",
    },
    '& .toastWrapper': {
      // padding: '24px 32px',
      // [theme.breakpoints.down('sm')]: {
      //   padding: '16px',
      // },
      '& .contentWrapper': {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        '& .crossButton': {
          minWidth: 'auto',
          width: '36px',
          height: '36px',
          backgroundColor: '#f7f7f7',
          borderRadius: '50%',
          border: '2px solid #fff',
          [theme.breakpoints.down('md')]: {
            width: '28px',
            height: '28px',
          },
          '& .MuiCircularProgress-root': {
            position: 'absolute',
            transform: 'rotate(-90Deg) !important',
            color: '#FF8707',
            opacity: '0.7',
            width: '36px !important',
            height: '36px !important',
            [theme.breakpoints.down('md')]: {
              width: '28px !important',
              height: '28px !important',
            },
            '& .MuiCircularProgress-svg': {
              '& circle': {
                strokeWidth: '2px'
              }
            }
          }
        },
        '& .toast-btn': {
          background: 'transparent',
          border: '1px solid #FF8707',
          ...theme.mixins.marginLeft(pxToRem(48)),
          [theme.breakpoints.down('md')]: {
            width: '100%',
            minWidth: pxToRem(240),
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: pxToRem(12),
            ...theme.mixins.marginLeft('auto'),
          },
          '& .btn-txt': {
            color: '#FF8707',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '16px'
          },
          '& .MuiButton-endIcon': {
            marginLeft: '8px !important',
            // marginBottom: '3px'
            '& .material-icons': {
              display: 'flex',
              height: 'unset',
              '& svg': {
                color: '#FF8707',
                width: '15px',
                height: '12px',
                '[dir="rtl"] &': {
                  transform: 'rotate(180Deg)'
                },
              },
            },
          }
        },
        '& .MuiButton-containedSizeSmall': {
          padding: `${pxToRem(9)} ${pxToRem(16)}`,
        }
        // '& .link': {
        //   color: '#FF8707',
        //   display: 'flex',
        //   alignItems: 'center',
        //   '& svg': {
        //     marginRight: '12px',
        //   },
        // },
      },
      '& .MuiTypography-body2Bold': {
        fontWeight: '700',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '17ch',
        '[lang="ar"] &': {
          fontSize: pxToRem(16),
        },
        [theme.breakpoints.down('md')]: {
          '[lang="ar"] &': {
            fontSize: pxToRem(14),
            maxWidth: '12ch',
          },
        },
      },
      '& .MuiTypography-body2': {
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        // maxWidth: '20ch',
        // '&.noEllipse': {
        //   overflow: 'visible',
        //   maxWidth: '100%',
        // },
        '[lang="ar"] &': {
          fontSize: pxToRem(16),
        },
        [theme.breakpoints.down('md')]: {
          '[lang="ar"] &': {
            fontSize: pxToRem(14),
          },
        },
      },
    },
  },
}));

export default useStyles;