import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import CategoryTag from '../../atoms/CategoryTag';

const TagsWrapper = props => {
  const {
    ctaLink,
    itemUniqueKey,
    borderStyle,
    colorStyle,
    className,
    customWidth = 0,
  } = props;

  const [catTags, setCatTags] = useState(null);
  const [calcDone, setCalcDone] = useState(false);
  const [tagsDetail, setTagsDetail] = useState([null]);
  const wrapperRef = useRef(null);

  useLayoutEffect(() => {
    const updateSize = () => {
      if (wrapperRef.current) {
        const wrapperWidth = wrapperRef.current.offsetWidth;
        // let allocatedWidth = wrapperWidth - (32 + 45);
        let allocatedWidth = wrapperWidth - (32 + 45) + customWidth;
        setTagsDetail(getWidth(catTags || [], allocatedWidth))
        let calculatingStatus = tagsDetail && Array.isArray(tagsDetail.displayTagArr) && tagsDetail.displayTagArr.length && tagsDetail.remaining;
        setCalcDone(calculatingStatus)
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [catTags])

  useEffect(() => {
    setCatTags(ctaLink)
  }, [itemUniqueKey])

  const getSelectWidthValue = (targetWidth, widthArr) => {
    const resultArr = [];
    let sum = 0;
    if (targetWidth < 100) {
      widthArr.forEach((val) => {
        resultArr.push(val.label);
      })
    } else {
      widthArr.forEach((val) => {
        if (val.width <= 0) { return }
        if ((sum + val.width) <= targetWidth) {
          sum += val.width;

          resultArr.push(val.label);
        }
      })
    }
    return resultArr
  }

  const getWidth = (truncatedCatTags, targetWidth = 0) => {
    if (truncatedCatTags.length === 0) return;
    const widthArr = truncatedCatTags.map((val) => {
      const boxElement = document.getElementsByClassName(val);
      if (boxElement.length === 0) return { label: val, width: 0 };
      let boxWidth = boxElement[0].offsetWidth;
      const boxElementStyle = boxElement[0].currentStyle || window.getComputedStyle(boxElement[0]);
      boxWidth += parseInt(boxElementStyle.marginRight + boxElementStyle.marginLeft);
      return { label: val, width: boxWidth };
    });

    const displayTagArr = getSelectWidthValue(targetWidth, widthArr);
    const remaining = widthArr.length - displayTagArr.length;
    if (remaining) {
      displayTagArr.push(`+${remaining}`);
    }
    return {
      displayTagArr, remaining,
    }
  };

  return (
    <div className={`cta-wrap tagswrapper ${calcDone ? 'calc-done' : tagsDetail?.remaining === 0 ? 'calc-done-last' : ''}`} ref={wrapperRef}>
      <div className='hidden-tags-for-calc'>
        {
          Array.isArray(ctaLink) && ctaLink
            .map(item => <CategoryTag text={item} key={item} className={`${item} ${className}`} borderStyle={borderStyle} colorStyle={colorStyle} />)
        }
      </div>
      {
        tagsDetail && tagsDetail.displayTagArr && tagsDetail.displayTagArr.map(item => <CategoryTag text={item} key={item} className={`${item} ${className}`} borderStyle={borderStyle} colorStyle={colorStyle} />)
      }
    </div>
  )
}

export default TagsWrapper