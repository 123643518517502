import React, { Fragment, useState, useEffect, useMemo } from 'react';
import Typography from '../../atoms/Typography';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import Link from '../../atoms/Link';
import PropTypes from 'prop-types';
import CategoryTag from '../../atoms/CategoryTag';
import PriceRangeTag from '../../atoms/PriceRangeTag';
import ViewsCounterTag from '../../atoms/ViewsCounterTag';
import Box from '../../molecules/Box';
import clsx from 'clsx';
import Picture from '../../atoms/Picture';
import useStyles from './style';
import EventCard from './Event';
import EventType2 from './EventType2';
import DctSvgIcon from '../../../components/atoms/Svg';
import Rating from '../../atoms/Rating';
import { pushAnaylyticsData } from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import { EVENT_TYPE, LISTING_TYPE } from '../../../../constants/index';
import { createDynamicLabels } from '../../../../utils/analytics';
import { numToKilo } from '../../../utils/numToKilo';
import aspectRatios from '../../../utils/AspectRatios';
import { get, isEmpty } from 'lodash';
// import { replaceKeysLabelText } from '../../../../utils/utility';
import { addHttpsImagePath } from './preProcess';
import OpenNowTag from '../../atoms/OpenNowTag';
import Highlighter from 'react-highlight-words';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import Favorite from '../../atoms/Favorite';
import ButtonMUI from '../../atoms/Button';
import FavoriteIcon from '../FavoriteIcon';
import DateLabel from '../../atoms/FavoriteItem/dateLabel';
import useFavStyles from '../../atoms/FavoriteItem/style';
import TagsWrapper from './TagsWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactPlayer from 'react-player';
import { breakpoints } from '../../../../styles/themes/vad/constants';
import { CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import { EXPLORE_MEDIA_MONK_MAP } from '../../../../constants/index';

const ConditionalWrapper = ({ condition, wrapper, children, props }) => {
  let href = props?.imageCtaUrl?.value?.href || props?.imageCtaUrl?.value || props?.imageCtaUrl || null;
  return (
    <Fragment>
      {' '}
      {condition ? (
        wrapper(children)
      ) : (
        <Link
          href={href}
          title={props.title && props.title.value || ''}
          isCustom={true}
          className={props.classes.root}
        >
          {children}
        </Link>
      )}
    </Fragment>
  )
};

let CarouselCard = ({
  hoverColour,
  videoUrl = '',
  cardType,
  title,
  description,
  desktopImage,
  mobileImage,
  aspectRatio,
  ctaLink,
  imageCtaUrl,
  sectionTitle,
  aspectRatioNeeded = false,
  pageName,
  componentName,
  carousalType,
  expenseRange,
  viewsCount,
  googlePlaceId,
  itemUniqueKey,
  isShowFavorite,
  isFavoriteItem = false,
  showFavoriteIcon = false,
  onClickItem = null,
  locationTitleTop = '',
  isParalax = true,
  colorTheme = '',
  eventCategories,
  showLoader = false,
  itemData,
  multiFilteredSelectedItems,
  selectedFilterCategory = [],
  supergraphicColor,
  isAdCard = false,
  isADRelatedEvents = false,
  ...other
}) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.lg}px)`);
  const isHTMLVideo = videoUrl.indexOf('.mp4') > 0
  const [reviewSpace, setReviewSpace] = useState(true);
  const [showFavIcon, setShowFavIcon] = useState(false);
  const [pillarButtonHovered, setPillarButtonHovered] = useState(false)
  const [isHover, setIsHover] = useState(false);
  let [isReady, setIsReady] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isCardFocus, setIsCardFocus] = useState(0);
  const {
    location,
    duration,
    rating,
    highlights,
    urlSlug,
    eventType,
    rating_image_url,
    language,
    defaultGooglePlaceId,
    forcedRatio,
    googlePlacesAPI = '',
    highlightWords,
    videoDesktop = '',
    videoMobile = '',
    index,
    activeCardIndex,
  } = other;
  let { num_reviews } = other;

  num_reviews = numToKilo(num_reviews);

  const ariaLabelText = useMemo(() => {
    return (rating && getDictionaryText('RatedItem')?.replace("{item}", rating)) || (num_reviews && `${getDictionaryText('TotalReviews')} ${num_reviews}`) || ''
  }, [rating, num_reviews])

  const ratingImage = {
    value: {
      src: addHttpsImagePath(rating_image_url),
      // alt: rating,
      alt: ariaLabelText,
    },
  };


  // Splitted the string separated with pipe symbol and pushed value to new array
  let filteredHighlights = [];
  highlights &&
    highlights.length > 0 &&
    highlights.forEach(highlight =>
      filteredHighlights.push(highlight.split(/\|/))
    );

  const classes = useStyles({
    cardType,
  });

  const favClasses = useFavStyles();

  const isCardTypeSearchResult = cardType === 'searchResult';

  const isPillarCarousel = cardType === 'pillarCarousel'
  const isMultiFilteredCard = cardType === 'multiFilteredCard'
  const isPillarCarouselComponent = componentName === 'PillarsCarousel'
  const isEventsCarousel = componentName === 'EventsCarousel'
  const isAdCalenderCarousel = componentName === 'SmartListSlider'
  const isEventsTabsFilter = componentName === 'EventsTabsFilter'
  const isMultiFilteredLayout = componentName === 'MultiFilteredLayout'

  const filteredSelectedItem = useMemo(() => {
    return Array.isArray(selectedFilterCategory) && selectedFilterCategory.some(item => item.id === itemData.id)
  }, [selectedFilterCategory, itemData])


  useEffect(() => {
    setTimeout(() => {
      setIsCardFocus(activeCardIndex === index)
    }, 1000);
  }, [activeCardIndex])

  useEffect(() => {
    if (isCardFocus && isMobile) {
      setIsTouched(true)
      setIsHover(true)
    }
    else {
      setIsTouched(false)
      setIsHover(false)
    }
  }, [isCardFocus])

  const getCardSpecificAnalyticsLabel = () => {
    let label;
    if (cardType === EVENT_TYPE) {
      label = createDynamicLabels([pageName, urlSlug]);
    } else {
      label = createDynamicLabels([
        pageName,
        componentName,
        sectionTitle && sectionTitle.value
          ? `${sectionTitle.value}-${title.value || title}`
          : title.value || title,
      ]);
    }
    return label;
  };

  const getCategoriesTag = () => {
    let categories = '';
    if (cardType === LISTING_TYPE) {
      categories = [...ctaLink].slice(0, 2).join('|');
    } else if (cardType === EVENT_TYPE) {
      let categoryTags = [...ctaLink];
      if (eventType && eventType.isPaid) {
        if (categoryTags && categoryTags.length > 0) {
          categoryTags[1] = eventType.isPaid;
        } else {
          categoryTags[0] = eventType.isPaid;
        }
      }
      categories = categoryTags.join('|');
    } else {
      categories = ctaLink;
    }
    categories = categories.filter(tag => tag);
    return categories;
  };

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'upperDecoration':
        return (
          <svg className={`${customClass} customSVG`} viewBox="0 0 336 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M-3.99614 -82L238.504 -78L335.504 -78C207.004 -20.5 29.0039 97 -3.99612 132L-3.99614 -82Z" fill={fill} />
          </svg>
        )
        break;

      case 'lowerDecoration':
        return (
          <svg className={`${customClass} customSVG`} viewBox="0 0 451 295" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,295c219.9-84.6,310-144.7,452.5-278.3V295L0,295z" fill={fill} />
          </svg>
        )
        break;

      case 'addIcon':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 12H20" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path d="M12 20L12 4" stroke="white" strokeWidth="2" strokeLinecap="round" />
          </svg>
        )

      case 'checkIcon':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 12.5L9.5 18L20.5 7" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        )

      case 'removeIcon':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4 12H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        )

      case 'multifilterSuperGraphic':
        return (
          <svg width="451" height="512" viewBox="0 0 451 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.7"
              d="M843.39 177.304L674.695 -498.148C612.303 -747.542 412.46 -859.518 223 -883.54C93.5499 -899.902 -31.4235 -878.622 -147.062 -831.335L-145.157 -821.783C-67.7154 -824.336 31.6346 -799.368 90.3111 -711.509C107.457 -686.446 122.983 -656.183 137.557 -620.717C137.653 -620.528 137.748 -620.244 137.843 -620.055L91.9305 -608.233C-345.095 -492.947 -630 -195.793 -630 140.609C-630 412.606 -428.156 628.52 -160.112 667.674C54.591 699.073 260.53 612.348 384.265 435.21H389.79C417.509 560.71 532.576 650.367 665.646 650.367H1076C997.511 589.745 912.544 471.432 843.39 177.304ZM220.047 383.856C-156.968 383.856 -274.702 -62.821 -133.536 -329.9C-86.8615 -418.233 -11.5153 -515.55 155.751 -572.957C205.759 -429.865 247.957 -215.653 314.063 91.8089L372.168 354.442C321.683 372.601 267.198 383.856 220.047 383.856Z"
              fill={fill}
            />
          </svg>
        )

      default:
        return <></>
    }

  }
  const onMouseEnterPillarCard = () => {
    if (isMobile) return
    setIsHover(true)
    setIsTouched(true)
  }

  const onMouseEnterBtnPillarCard = () => {
    setPillarButtonHovered(true)
  }

  const onMouseLeaveBtnPillarCard = () => {
    setPillarButtonHovered(false)
  }

  const onMouseLeavePillarCard = () => {
    if (isMobile) return
    setIsHover(false)
    setIsReady(false);
  }

  const addTileAnalyticsInfo = from => {
    let eventObject = analyticsData.pageBodyInteraction.tileClick;
    if (from === 'favoriteNavigationItem') {
      eventObject = analyticsData.pageBodyInteraction.favoriteNavTileClick;
    }
    const obj = {
      ...eventObject,
      ...{
        label: getCardSpecificAnalyticsLabel(),
        tileCategory: ctaLink ? getCategoriesTag() : '',
      },
    };
    pushAnaylyticsData(obj);
  };

  const renderCard = () => {
    if (isEventsCarousel || isAdCalenderCarousel || isEventsTabsFilter) {
      const categories = [...eventCategories].slice(0, 1).join(',');
      return (
        <EventType2
          title={title}
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          ctaLink={ctaLink}
          isAdCard={isAdCard}
          imageCtaUrl={imageCtaUrl}
          componentName={componentName}
          cardType={cardType}
          other={other}
          itemUniqueKey={itemUniqueKey}
          isShowFavorite={isShowFavorite}
          // forcedRatio={'16x8'}
          showFavoriteIcon={showFavoriteIcon}
          eventCategories={isAdCard ? eventCategories : categories}
        />
      )
    }
    else if (cardType === EVENT_TYPE) {
      return (
        <EventCard
          title={title}
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          ctaLink={ctaLink}
          imageCtaUrl={imageCtaUrl}
          componentName={componentName}
          cardType={cardType}
          other={other}
          itemUniqueKey={itemUniqueKey}
          isShowFavorite={isShowFavorite}
          forcedRatio={forcedRatio}
          showFavoriteIcon={showFavoriteIcon}
        />
      );
    } else if (cardType === LISTING_TYPE || isCardTypeSearchResult) {
      return listingCard();
    }
    else if (isPillarCarousel) {
      return pillarCard();
    }
    else {
      return commonCard();
    }
  };

  const renderOpenNowTag = (
    googlePlaceId,
    defaultGooglePlaceId,
    language,
    isAlone,
    openNowText = 'openNow'
  ) => {
    if (googlePlaceId) {
      return (
        <OpenNowTag
          setReviewSpace={setReviewSpace}
          isAlone={isAlone}
          googlePlacesAPI={googlePlacesAPI}
          googlePlaceId={googlePlaceId}
          defaultGooglePlaceId={defaultGooglePlaceId}
          language={language}
          openNowText={openNowText}
        ></OpenNowTag>
      );
    } else {
      return null;
    }
  };

  const findChunksAtBeginningOfWords = ({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight,
  }) => {
    const chunks = [];
    const textLow = textToHighlight.toLowerCase();

    // if language is chinese we will match character ignoring spaces.
    if (language === 'zh') {
      searchWords.forEach(sw => {
        const swLow = sw.toLowerCase();
        [...textLow.matchAll(swLow)].forEach(item => {
          chunks.push({
            start: item.index,
            end: item.index + swLow.length,
          });
        });
      });
      return chunks;
    }

    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const sep = /[-\s]/;

    // Match at the beginning of each new word
    // New word start after whitespace or - (hyphen)
    const singleTextWords = textLow.split(sep);

    // It could be possible that there are multiple spaces between words
    // Hence we store the index (position) of each single word with textToHighlight
    let fromIndex = 0;
    const singleTextWordsWithPos = singleTextWords.map(s => {
      const indexInWord = textLow.indexOf(s, fromIndex);
      fromIndex = indexInWord;
      return {
        word: s,
        index: indexInWord,
      };
    });

    // Add chunks for every searchWord
    searchWords.forEach(sw => {
      const swLow = sw.toLowerCase();
      // Do it for every single text word
      singleTextWordsWithPos.forEach(s => {
        if (s.word.startsWith(swLow)) {
          const start = s.index;
          const end = s.index + swLow.length;
          chunks.push({
            start,
            end,
          });
        }
      });

      // The complete word including whitespace should also be handled, e.g.
      // searchWord='Angela Mer' should be highlighted in 'Angela Merkel'
      if (textLow.startsWith(swLow)) {
        const start = 0;
        const end = swLow.length;
        chunks.push({
          start,
          end,
        });
      }
    });

    return chunks;
  };

  const highlightIfNeeded = (highlightWords, entireText) => {
    const highlightWordsList = highlightWords && highlightWords.split(' ');
    if (highlightWordsList && highlightWordsList.length > 0) {
      return (
        <Highlighter
          highlightClassName="search-highlight"
          searchWords={highlightWordsList}
          autoEscape={true}
          findChunks={findChunksAtBeginningOfWords}
          textToHighlight={entireText}
        />
      );
    } else {
      return <Text field={{ value: entireText }} />;
    }
  };

  const favoriteCard = cardType => {
    let time = '';
    let locationLabel = '';

    if (componentName !== 'ExploreMap') {
      if (cardType === EVENT_TYPE) {
        time = `${other.openTime} - ${other.closeTime}`;
        locationLabel = `${other.locationTitle} - ${other.region}`;
      } else {
        locationLabel = location;
      }
    }

    return (
      <li
        className={clsx(favClasses.listItem, {
          [favClasses.width95pc]: ['ExploreMap', EXPLORE_MEDIA_MONK_MAP].includes(componentName),
        })}
        onClick={e => addTileAnalyticsInfo('favoriteNavigationItem')}
      >
        <ConditionalWrapper
          condition={!!onClickItem}
          props={{
            title,
            classes,
            imageCtaUrl,
          }}
          wrapper={children => (
            <div
              className="listItemContainer"
              onClick={() => onClickItem(itemUniqueKey)}
            >
              {children}
            </div>
          )}
        >
          <>
            <Box width="95px" height="95px" className={classes.posRelative}>
              <Picture
                media={{
                  image: desktopImage,
                  mobileImage: mobileImage,
                  disableLazyLoad: true,
                  aspectRatio: aspectRatios['1x1'],
                  forcedRatio: '1x1',
                }}
                width={95}
                isParalax={false}
              />

              {/* {viewsCount && (
              <div className={classes.favViewsCounterTag}>
                <ViewsCounterTag value={viewsCount} />
              </div>
            )}
            {expenseRange && (
              <div className={classes.favPriceRangeTag}>
                <PriceRangeTag value={expenseRange} />
              </div>
            )} */}
            </Box>

            <div className={favClasses.listItemTextWrap}>
              {cardType === EVENT_TYPE && (
                <DateLabel
                  startDate={other.startDate}
                  endDate={other.endDate}
                  language={language}
                />
              )}

              <Typography
                component="span"
                variant={'body4Bold'}
                classes={
                  cardType !== EVENT_TYPE ? { root: favClasses.pr12 } : {}
                }
              >
                {title}
              </Typography>

              {locationLabel && cardType !== EVENT_TYPE && (
                <Typography
                  variant={'body5'}
                  classes={{ root: favClasses.ltGray }}
                  component="span"
                >
                  {locationLabel}
                </Typography>
              )}

              {duration && (
                <Typography
                  variant="body3"
                  component="div"
                  classes={{ root: classes.duration }}
                >
                  <Box component="span" display="inline-flex" className="icon">
                    <DctSvgIcon name="AccessTimeIcon" />
                  </Box>
                  <Box component="span" display="inline-flex">
                    {duration}
                  </Box>
                </Typography>
              )}

              {time && cardType !== EVENT_TYPE && (
                <Typography
                  variant={'labelBlack'}
                  classes={{ root: favClasses.mt4 }}
                  component="span"
                >
                  {time}
                </Typography>
              )}

              <Box className={favClasses.overViewSection}>
                <>
                  {ratingImage && ratingImage.value.src && (
                    <div className="rating-sec">
                      {ratingImage.value.src && (
                        <Box
                          component="div"
                          className={favClasses.ratingImageWithLogo}
                        >
                          <Picture
                            className={`${favClasses.ratingTripAdvisor} rating-img`}
                            media={{
                              image: ratingImage,
                              renditionNeeded: false,
                              fallbackBackgroundNeeded: false,
                              forcedRatio: true,
                            }}
                            alternateAltText={
                              ratingImage &&
                              ratingImage.value &&
                              ratingImage.value.rating
                            }
                            isParalax={false}
                          />
                        </Box>
                      )}
                      {num_reviews && num_reviews !== '' && (
                        <Typography
                          variant="labelRegular"
                          component="span"
                          className={favClasses.totalReviews}
                        >
                          <Text
                            field={{
                              value: `${num_reviews} ${getDictionaryText(
                                'Reviews'
                              )}`,
                            }}
                          ></Text>
                        </Typography>
                      )}
                    </div>
                  )}
                </>
                {googlePlaceId && (
                  <div className="open-close-sec">
                    {renderOpenNowTag(
                      googlePlaceId,
                      defaultGooglePlaceId,
                      language,
                      false,
                      'Open'
                    )}
                  </div>
                )}
              </Box>

              {ctaLink && ctaLink.length > 0 && (
                <div
                  className={clsx({
                    [favClasses.tagsWrapper]: cardType === EVENT_TYPE,
                  }, classes.customWrapper)}
                >
                  {ctaLink.slice(0, 2).map(tag => (
                    <CategoryTag
                      text={tag}
                      key={tag}
                      from="favoritesNav"
                      className={clsx(favClasses.categoryTag, {
                        [favClasses.eventTag]: cardType === EVENT_TYPE,
                        [favClasses.listTag]: cardType === LISTING_TYPE,
                      }, 'categoryAlign')}
                    />
                  ))}
                </div>
              )}
            </div>
            {componentName !== EXPLORE_MEDIA_MONK_MAP && (
              <Favorite
                ariaLabel={title}
                itemUniqueKey={itemUniqueKey}
                isShowFavorite={isShowFavorite}
                componentName={!!onClickItem ? 'mapComponent' : 'favoriteNav'}
              />
            )}
          </>
        </ConditionalWrapper>
      </li>
    );
  };

  const commonCard = () => {
    const Type3WithDescription = carousalType === 'Type3WithDescription'
    return (
      <>
        {showFavoriteIcon && (
          <Favorite
            ariaLabel={title}
            itemUniqueKey={itemUniqueKey}
            isShowFavorite={isShowFavorite}
            componentName={componentName}
          />
        )}
        <ConditionalWrapper
          condition={isEmpty(imageCtaUrl)}
          props={{
            title,
            classes,
            imageCtaUrl,
          }}
          wrapper={children => (
            <div className={classes.listItemContainer}>
              {children}
            </div>
          )}
        >
          <Box className={`${classes.cardWrapper} card-wrapper ${Type3WithDescription ? 'type3-description-wrapper' : ''}`}>
            <Box className={`${classes.imageWrapper} carousel-picture`}>
              {
                /*
                  If video is available use video (looped without controls) otherwise Image.
                  Change video to Desktop or Mobile based on screen size.
                */
                videoDesktop && videoDesktop.value && (videoDesktop.value.href || videoDesktop.value.url) ? (
                  <video
                    controls={false}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={clsx(classes.video, 'carousel-item-video')}
                  >
                    <source
                      src={(videoDesktop.value.href || videoDesktop.value.url) || (videoMobile.value.href || videoMobile.value.url)}
                      media="(min-width: 768px)"
                    />
                    <source
                      src={(videoMobile.value.href || videoMobile.value.url) || (videoDesktop.value.href || videoDesktop.value.url)}
                      media="(max-width: 767px)"
                    />
                  </video>
                ) : (
                  <Picture
                    media={{
                      image: desktopImage,
                      mobileImage: mobileImage,
                      aspectRatio,
                      forcedRatio,
                    }}
                    alternateAltText={
                      typeof title === 'object' && title ? title.value : title
                    }
                    isParalax={isParalax}
                  />
                )

              }
            </Box>
            <Box
              pt={{ xs: 1 }}
              pb={{ xs: 3 }}
              p={{ lg: 3 }}
              px={{ xs: 1 }}
              className={clsx(classes.cardContent, `${carousalType !== 'Type1' && ' common-card'} card-description`)}
            >
              {carousalType !== 'Type1' && <div className='contentLeftBar'></div>}
              <div className='card-description-flex'>
                {locationTitleTop && !Type3WithDescription && (
                  <Typography
                    variant={'body5'}
                    className={clsx(classes.locationTitleTop, 'location-title-top')}
                    component="span"
                  >
                    {locationTitleTop}
                  </Typography>
                )}
                {title && (
                  <Typography variant={Type3WithDescription ? 'body1Bold22' : 'h5'} component="h3" className='card-desc-title'>
                    <Text field={title?.value ? title : { value: title.value }} />
                  </Typography>
                )}
                {ctaLink && !Type3WithDescription && (
                  <div className='common-card-tag-wrapper'>
                    <CategoryTag
                      text={ctaLink}
                      isAuthorable={true}
                      className={`${carousalType === 'Type1' && colorTheme === 'Light' ? 'categoryTagWhite' : ''} common-card-tag`}
                    />
                  </div>
                )}
              </div>
            </Box>
            {description && Type3WithDescription && (
              <Typography variant="body2" className={clsx(classes.descriptionAlignment, 'description-txt')}>
                {description}
              </Typography>
            )}
            {Type3WithDescription && ctaLink.href && ctaLink.text && (
              <ButtonMUI
                buttonType={'form'}
                type={'submit'}
                hasBorder={false}
                size="large"
                field={{ value: { href: ctaLink.href } }}
              >
                <Typography variant="body2Bold" component="span"
                >
                  <Text field={{ value: ctaLink.text }} />
                </Typography>
              </ButtonMUI>
            )}
          </Box>
        </ConditionalWrapper>
      </>
    );
  };

  const listingCard = () => {
    const boxElement = document.querySelector('.card-description-flex')
    let boxWidth = boxElement && boxElement.clientWidth || 0;

    if (!get(desktopImage, 'value.src', '')) {
      desktopImage = {
        value: {
          src:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          '3x2-card': {
            large:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          },
        },
      };
    }
    if (!get(mobileImage, 'value.src', '')) {
      mobileImage = {
        value: {
          src:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          '3x2-card': {
            large:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
            small:
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAACCAQAAAA3fa6RAAAADklEQVR42mNkAANGCAUAACMAA2w/AMgAAAAASUVORK5CYII=',
          },
        },
      };
    }
    if (showLoader) {
      return (
        <>
          <Box
            className={clsx(
              classes.cardWrapper,
              'listing-card',
              'loaderAlignment'
            )}
          >
            <Box className='loaderCard'>
              <CircularProgress value={15} color="inherit" />
            </Box>
          </Box>
        </>
      )
    }


    return (
      <>
        {showFavoriteIcon && (
          <Favorite
            ariaLabel={title}
            itemUniqueKey={itemUniqueKey}
            isShowFavorite={isShowFavorite}
            componentName={componentName}
          />
        )}
        <Box

          className={classes.root}
        >
          <Box
            className={clsx(
              classes.cardWrapper,
              'listing-card',
              isCardTypeSearchResult && 'horizontal-card'
            )}
          >
            <Box
              className={clsx(
                classes.imageWrapper,
                isCardTypeSearchResult && 'img-wrapper',
                'fallback-img',
                'carousel-picture'
              )}
            >
              <Picture
                media={{
                  image: desktopImage,
                  mobileImage: mobileImage,
                  renditionNeeded: aspectRatioNeeded,
                  aspectRatio,
                  forcedRatio,
                }}
                alternateAltText={
                  typeof title === 'object' && title ? title.value : title
                }
              />

              {viewsCount && (
                <div className={classes.viewsCounterTag}>
                  <ViewsCounterTag value={viewsCount} />
                </div>
              )}
              {expenseRange && (
                <div className={classes.priceRangeTag}>
                  <PriceRangeTag value={expenseRange} />
                </div>
              )}
            </Box>

            <Box
              pt={{ xs: 1 }}
              pb={{ xs: 3 }}
              p={{ lg: 3 }}
              className={clsx(classes.cardContent, 'card-description')}
            >
              {!isCardTypeSearchResult && <div className='contentLeftBar'></div>}
              <div className='card-description-flex'>
                <div className='card-description-wrapper'>
                  <Link
                    href={imageCtaUrl}
                    title={title || ''}
                    isCustom={true}
                    className={'customLink'}
                  >
                    {title && (
                      <Typography variant="cardTitle1" component="h3">
                        {highlightIfNeeded(highlightWords, title)}
                        {rating && <Rating rating={`${rating}`} />}
                      </Typography>
                    )}
                  </Link>
                  {description && (
                    <Typography variant="body2" component="p">
                      {highlightIfNeeded(highlightWords, description)}
                    </Typography>
                  )}
                  {ratingImage.value.src ? (
                    <Box className={classes.overViewSection}>
                      <div className="ratign-sec">
                        {ratingImage.value.src && (
                          <Box
                            component="div"
                            className={classes.ratingImageWithLogo}
                          >
                            <Picture
                              className={`${classes.ratingTripAdvisor} rating-img`}
                              media={{
                                image: ratingImage,
                                renditionNeeded: false,
                                fallbackBackgroundNeeded: false,
                                forcedRatio,
                              }}
                              alternateAltText={
                                ratingImage &&
                                ratingImage.value &&
                                ratingImage.value.rating
                              }
                              isParalax={false}
                            />
                          </Box>
                        )}
                        {num_reviews && num_reviews !== '' ? (
                          <Typography
                            variant="labelRegular"
                            component="span"
                            className={classes.totalReviews}
                          >
                            <Text
                              field={{
                                value: `${num_reviews} ${getDictionaryText(
                                  'Reviews'
                                )}`,
                              }}
                            ></Text>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="open-close-sec">
                        {renderOpenNowTag(
                          googlePlaceId,
                          defaultGooglePlaceId,
                          language,
                          false,
                        )}
                      </div>
                    </Box>
                  ) : (
                    <React.Fragment>
                      {renderOpenNowTag(
                        googlePlaceId,
                        defaultGooglePlaceId,
                        language,
                        true,
                      )}
                    </React.Fragment>
                  )}
                  {/* <div>    // This is part of VAD1.3
                {filteredHighlights &&
                  filteredHighlights.length > 0 &&
                  filteredHighlights.map((highlight, i) => {
                    const icon = {
                      value: {
                        src: highlight[0],
                        alt: highlight[1],
                      },
                    };
                    return (
                      <Typography
                        variant="body3"
                        component="div"
                        classes={{ root: classes.highlights }}
                        key={i}
                      >
                        <Box
                          component="span"
                          display="inline-flex"
                          className="icon"
                        >
                          <Picture
                            media={{ image: icon, renditionNeeded: false }}
                          />
                        </Box>
                        <Box component="span" display="inline-flex">
                          {highlight[1]}
                        </Box>
                      </Typography>
                    );
                  })}
              </div> */}
                  {duration && (
                    <Typography
                      variant="body3"
                      component="div"
                      classes={{ root: classes.duration }}
                    >
                      <Box
                        component="span"
                        display="inline-flex"
                        className="icon"
                      >
                        <DctSvgIcon name="AccessTimeIcon" />
                      </Box>
                      <Box component="span" display="inline-flex">
                        {duration}
                      </Box>
                    </Typography>
                  )}
                </div>
                <div className={location ? 'bot-area' : ''}>
                  {location && (
                    <Typography
                      variant="body3"
                      component="div"
                      classes={{ root: clsx(classes.location, 'location') }}
                    >
                      <Box component="span" display="inline-flex">
                        {location}
                      </Box>
                    </Typography>
                  )}
                  <TagsWrapper
                    ctaLink={ctaLink}
                    itemUniqueKey={itemUniqueKey}
                    title={title}
                    boxWidth={boxWidth}
                  />
                </div>
              </div>
            </Box>
          </Box>
        </Box>
      </>
    );
  };


  const onProgress = (e) => {
    if (!isReady && e.played > 0.0001) {
      setIsReady(true);
    }
  };

  const multiFilterSelectBtnProps = {
    onMouseEnter: onMouseEnterBtnPillarCard,
    onMouseLeave: onMouseLeaveBtnPillarCard,
  };

  const pillarCardUrl = {
    ...(isPillarCarouselComponent && {
      condition: isEmpty(imageCtaUrl),
    }),
    ...(!isPillarCarouselComponent && {
      condition: true,
    }),
  };

  const pillarMultiCardProps = useMemo(() => {
    return {
      ...(isMultiFilteredLayout && {
        key: Math.random()
      }),
    };
  }, [title]);

  const pillarCard = () => {
    const pillarDescription = { value: description }

    return (
      <>
        <ConditionalWrapper
          // {...pillarCardUrl}
          condition={isEmpty(imageCtaUrl)}
          props={{
            title,
            classes,
            imageCtaUrl,
          }}
          wrapper={children => (
            <div className={classes.listItemContainer}>
              {children}
            </div>
          )}
        >

          <Box className={`${classes.cardWrapper} card-wrapper type3-description-wrapper`}>
            <Box onClick={() => { multiFilteredSelectedItems(itemData) }}
              className={`pillarImageWrapper ${isMultiFilteredLayout ? 'multiFilterWrapper' : ''}`}
              onMouseEnter={onMouseEnterPillarCard}
              onMouseLeave={onMouseLeavePillarCard}
            >
              {isMultiFilteredLayout &&
                <>
                  <ButtonMUI
                    iconOnly={true}
                    hasBorder={false}
                    size="large"
                    hideEndIcon={true}
                    className={`multi-filter-btn-hovered ${isMobile ? 'mobile-multi-filter-btn' : ''} ${isMobile && filteredSelectedItem ? 'mobile-multi-filter-btn-selected' : ''}  ${!isMobile && filteredSelectedItem && pillarButtonHovered ? 'multi-filter-btn-hovered-selected' : (!isMobile && filteredSelectedItem) ? 'multi-filter-btn-selected' : ''}`}
                    {...multiFilterSelectBtnProps}
                  >
                    <>
                      <Box className='filterBtnAddCategory'>
                        {createSVG('addIcon', '', '')}
                        <span className='filterBtnText'>{getDictionaryText('multifilter_select_text')} </span>
                      </Box>
                      <Box className='filterBtnRemoveCategory'>
                        {/* <Icon><RemoveIcon /></Icon> */}
                        {createSVG('removeIcon', '', '')}
                        <span className='filterBtnText'>{getDictionaryText('multifilter_deselect_text')}</span>
                      </Box>
                      <Box className='filterBtnCheckedCategory'>
                        {/* <Icon><CheckIcon /></Icon> */}
                        {createSVG('checkIcon', '', '')}
                      </Box>
                    </>

                  </ButtonMUI>
                  <Box className={`multi-filtered-super-img ${!isMobile && filteredSelectedItem ? 'category-selected-super-image' : ''} ${filteredSelectedItem && pillarButtonHovered ? 'category-selected-super-hover-image' : ''}`}>
                    {createSVG('multifilterSuperGraphic', supergraphicColor, '')}
                  </Box>
                </>
              }
              {isPillarCarouselComponent && createSVG('upperDecoration', hoverColour?.value, 'animateBox1')}
              {isTouched && isHTMLVideo && (
                <Box className='pillarCarouselVideo' style={{ opacity: isHover ? 1 : 0 }}>
                  <ReactPlayer
                    playing={isHover}
                    url={videoUrl}
                    loop
                    muted
                    controls={false}
                    width="100%"
                    height="100%"
                    onProgress={onProgress}
                    playsinline={true}
                  />
                </Box>
              )}
              <>
                <Box className={`pillarContent ${isMultiFilteredLayout ? 'multifilterContent' : ''}`}>
                  {title && (
                    <Typography variant="h5Bold" component="h2" className="pillarTitle" >
                      <Text field={title} />
                    </Typography>
                  )}
                  {pillarDescription && !isMultiFilteredLayout && (
                    <Typography variant="body1a" component="h2" className="pillarDescription" >
                      <Text field={pillarDescription} />
                    </Typography>
                  )}
                </Box>

                <Picture
                  {...pillarMultiCardProps}
                  media={{
                    image: desktopImage,
                    mobileImage: mobileImage,
                    // aspectRatio: aspectRatios['4x5'],
                    aspectRatio: aspectRatios['3x4-card'],
                    forcedRatio,
                  }}
                  alternateAltText={
                    typeof title === 'object' && title ? title.value : title
                  }
                  isParalax={false}
                />
              </>
              {isPillarCarouselComponent && createSVG('lowerDecoration', hoverColour?.value, 'animateBox')}
            </Box>
          </Box>
        </ConditionalWrapper>

      </>
    );
  };

  return (
    <>
      {!isFavoriteItem && (
        <div
          onClick={(e) => {
            const isClickOnParent = e.currentTarget.contains(e.target);
            if (isClickOnParent) {
              addTileAnalyticsInfo()
            }
          }}
          className={`${!isFavoriteItem ? classes.rtlClass : ''}`}
        >
          {renderCard()}
        </div>
      )}
      {isFavoriteItem && <>{favoriteCard(cardType)}</>}
    </>
  );
};

CarouselCard.propTypes = {
  description: PropTypes.string,
  carousalType: PropTypes.string,
  cardType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFavoriteItem: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desktopImage: PropTypes.object,
  mobileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  imageCtaUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  aspectRatio: PropTypes.shape({
    mobile: PropTypes.string,
    desktop: PropTypes.string,
  }),
  sectionTitle: PropTypes.object,
  // isShowFavorite: PropTypes.bool,
};

CarouselCard.defaultProps = {
  cardType: false,
  isFavoriteItem: false,
  title: '',
  description: '',
  desktopImage: {},
  mobileImage: {},
  ctaLink: '',
  imageCtaUrl: '',
  sectionTitle: {
    value: '',
  },
  aspectRatio: aspectRatios['3x2-card'],
  // isShowFavorite: true
};

export default React.memo(
  withSitecoreContext()(withNamespaces()(CarouselCard))
);
