import React from 'react'
import Typography from '../../Typography'
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '@material-ui/core';
import { getDictionaryText } from '../../../../utils/getDictionaryText';

const Counter = (props) => {
  const {
    fieldLabel,
    selectedValue,
    incrementorBtnDisable, // number for counter to disable btn
    decrementorBtnDisable, // number for counter to disable btn,
    customStyle = '',
    validationMessage,
  } = props.data;
  const counterValue = selectedValue.value

  const renderText = (variant, label) => (
    <Typography variant={variant} gutterBottom={false}>
      <Text field={label} />
    </Typography>
  );

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'decrement':
        return (
          <svg className={customClass} width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="2" fill={fill} />
          </svg>
        )
        break;
      case 'increment':
        return (
          <svg className={customClass} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9" y="18" width="16" height="2" transform="rotate(-90 9 18)" fill={fill} />
            <rect x="2" y="9" width="16" height="2" fill={fill} />
          </svg>
        )
        break;
      default:
        return <></>
    }

  }

  const counterButton = (name, label, isDisabled = false) => {
    return (
      <Button disabled={isDisabled} onClick={() => props.onClick(name, Number(counterValue))}>{label}</Button>
    );
  }

  const isDecrementorBtnDisabled = decrementorBtnDisable && counterValue <= decrementorBtnDisable || false
  const isIncrementorBtnDisabled = incrementorBtnDisable && counterValue >= incrementorBtnDisable || false

  return (
    <div className='counterBox'>
      {renderText('body2', fieldLabel)}
      <div className='counterWrapper'>
        {counterButton('decrement', createSVG('decrement', isDecrementorBtnDisabled ? '#D9D9D9' : '#ffffff'), isDecrementorBtnDisabled)}
        <div className='valueWrapper'> <Typography variant={'body3Bold'} gutterBottom={false}>{counterValue} {getDictionaryText('ItineraryDays')}</Typography></div>
        {counterButton('increment', createSVG('increment', isIncrementorBtnDisabled ? '#D9D9D9' : '#ffffff'), isIncrementorBtnDisabled)}
      </div>
      {validationMessage && isIncrementorBtnDisabled && <Typography variant="body3" className="errortext" component="span">{validationMessage.value}</Typography>}
    </div>
  )
}

export default Counter