import React from 'react';
import { get } from 'lodash';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ReCAPTCHA from 'react-google-recaptcha';

import { formFieldTypes } from './constants';
import Grid from '../../../components/molecules/Grid';
import useStyles from './RequestForProposal.style';
import Typography from '../../../components/atoms/Typography';
import {
  Select,
  CheckBox,
  InputField,
  RadioList,
} from '../../../components/atoms/Forms';
import ButtonMUI from '../../../components/atoms/Button';
import { canUseDOM } from '../../../utils/canUseDOM';
import Calendar from '../../../components/atoms/Forms/Calendar';
import InputFile from '../../../components/atoms/Forms/InputFile';
import clsx from 'clsx';
import Counter from '../../../components/atoms/Forms/Counter';
import { Button } from '@material-ui/core';

const RenderRFPForm = props => {
  const classes = useStyles(props);
  const formFields = get(props, 'formData.fields', '');
  const formTitle = get(props, 'formData.formTitle', '');
  const formDataList = get(props, 'formDataList', '');
  const captchaApiKey = get(props, 'captchaApiKey', '');
  const isFormValid = get(props, 'isFormValid', '');
  const disableFileInput = get(props, 'disableFile', '');
  const errors = get(props, 'errors', '');
  const {
    SINGLELINETEXT,
    EMAIL,
    PHONE,
    MULTILINETEXT,
    DROPDOWN,
    RADIOBUTTONLIST,
    CHECKBOX,
    DATE,
    RANGEDATE,
    CAPTCHA,
    SUBMIT,
    FILE,
    NUMBER,
    COUNTER,
    CUSTOMBUTTON,
  } = formFieldTypes;

  // renders typography based on type provided
  const renderTitle = (variant, type, value) => (
    <Typography
      variant={variant}
      component={type}
      gutterBottom
      className={clsx(classes.formHeading, 'formHeading')}
    >
      <Text field={value} />
    </Typography>
  );

  // extract fields from data and call renderField with field data
  const renderFormFields = () => {
    return formFields.map(formField => {
      const field = get(formField, 'fields', '');
      if (field && field.fieldGroup && field.fieldGroup.length) {
        const groupTitle = get(field, 'groupTitle', '');
        return (
          <Grid item className={classes.formGroup}>
            {groupTitle && groupTitle.value && (
              <Grid item className={classes.formTitle}>
                {renderTitle('body2Bold', 'span', groupTitle)}
              </Grid>
            )}
            {field.fieldGroup.map(groupField => {
              const field = get(groupField, 'fields', '');
              return renderField(field);
            })}
          </Grid>
        );
      } else {
        return <Grid item>{renderField(field)}</Grid>;
      }
    });
  };

  // function returns error status of range date field
  const getDateErrorStatus = (formDataList, fieldName) => {
    const startDate = formDataList[fieldName && fieldName[0]] || '';
    const endDate = formDataList[fieldName && fieldName[1]] || '';
    if ((!startDate && !endDate) || (startDate && endDate)) {
      return false;
    } else {
      return true;
    }
  }

  // render individual field
  const renderField = field => {
    const { value: fieldType } = field.fieldType;
    const invalidEmail = errors['invalidEmail'];
    const parentKey = get(field, 'parentKey', '');
    if (parentKey && parentKey.value) {
      const parentValue = get(field, 'parentValue.fields.key.value', '');
      const parentMappingValue = get(
        field,
        'parentValue.fields.mappingId.value',
        ''
      );
      if (
        !(
          formDataList[parentKey && parentKey.value] &&
          (formDataList[parentKey && parentKey.value] === parentValue ||
            formDataList[parentKey && parentKey.value] === parentMappingValue)
        )
      ) {
        return;
      }
    }
    let fieldProps = {
      ...field,
      selectedValue: {
        value:
          formDataList[field.fieldName && field.fieldName.value] ||
          (field.defaultValue && field.defaultValue.fields.displayText.value) ||
          '',
      },
    };
    let otherProps = {};

    if (fieldType === MULTILINETEXT) {
      fieldProps = {
        ...fieldProps,
        multiline: true,
        rows: 3,
        rowsMax: 4,
      };
    }
    if (fieldType === EMAIL) {
      fieldProps = {
        ...fieldProps,
        invalidEmail: invalidEmail,
      };
    }
    if (fieldType === FILE) {
      fieldProps = {
        ...fieldProps,
        selectedValue: {
          value: formDataList[field.fieldName && field.fieldName.value] || [],
        },
        error: errors['fileError'],
        uploadError: errors['uploadError'],
        disableFileInput: disableFileInput,
      };
    }
    if (fieldType === PHONE || fieldType === NUMBER) {
      fieldProps = {
        ...fieldProps,
        type: NUMBER,
      };
      otherProps = {
        inputProps: {
          className: 'no-flip',
        },
      };
    }
    if (fieldType === DATE) {
      fieldProps = {
        ...fieldProps,
        startDate: {
          value: formDataList[field.fieldName && field.fieldName.value] || '',
        },
        startDateName: {
          value: field.fieldName && field.fieldName.value,
        },
      };
      otherProps = {
        monthsShown: 1,
      };
    }
    if (fieldType === DATE) {
      otherProps = {
        maxDateYearCount: 1,
      };
    }
    if (fieldType === RANGEDATE) {
      const fieldName = field.fieldName && field.fieldName.value.split('|');
      fieldProps = {
        ...fieldProps,
        startDate: {
          value: formDataList[fieldName && fieldName[0]] || '',
        },
        endDate: {
          value: formDataList[fieldName && fieldName[1]] || '',
        },
        startDateName: {
          value: fieldName && fieldName[0],
        },
        endDateName: {
          value: fieldName && fieldName[1],
        },
        showError: getDateErrorStatus(formDataList, fieldName),
        eventCalender: true,
      };
      otherProps = {
        monthsShown: 2,
        maxDateYearCount: 10,
      };
    }
    if (fieldType === SINGLELINETEXT) {
      otherProps = {
        ...field?.extraProps
      };
    }

    switch (fieldType) {
      case SINGLELINETEXT:
      case EMAIL:
      case PHONE:
      case NUMBER:
      case MULTILINETEXT:
        return (
          <InputField
            data={fieldProps}
            onChange={props.onChange}
            className={fieldType === MULTILINETEXT ? 'fullWidth' : ''}
            other={otherProps}
          />
        );
      case FILE:
        return (
          <InputFile
            data={fieldProps}
            onChange={props.onChange}
            onRemoveClick={props.onRemoveClick}
            shouldAllowMultipleUpload={false}
          />
        );
      case DROPDOWN:
        return (
          <Select
            data={fieldProps}
            onChange={props.onChange}
            className="selectMenu"
          />
        );
      case RADIOBUTTONLIST:
        return (
          <RadioList
            data={fieldProps}
            onChange={props.onChange}
            className={'fullWidth boldText'}
            customStyle={field.customStyle}
          />
        );
      case CHECKBOX:
        return (
          <CheckBox
            data={field}
            selected={formDataList[field.fieldName && field.fieldName.value]}
            onChange={props.handleCheckboxChange}
            className={'fullWidth'}
          />
        );
      case DATE:
      case RANGEDATE: {
        return (
          <Calendar
            data={fieldProps}
            onChange={props.onChange}
            {...otherProps}
            shouldSetMinDate={true}
          />
        );
      }
      case CAPTCHA: {
        if (canUseDOM) {
          window.recaptchaOptions = {
            useRecaptchaNet: true,
          };
        }
        return (
          field.enableCaptcha.value &&
          captchaApiKey && (
            <div className={classes.captchaWrapper}>
              <ReCAPTCHA
                sitekey={captchaApiKey}
                onChange={props.recaptchaCallback}
              />
            </div>
          )
        );
      }
      case SUBMIT: {
        const btnSize = field.size ?? 'medium'
        return (
          <ButtonMUI
            buttonType={'form'}
            type={'submit'}
            hasBorder={false}
            size={btnSize}
            field={{ value: { text: field.fieldLabel.value } }}
            className={`${classes.submitButton} ${field.customStyle || ''}`}
            disabled={!isFormValid}
          />
        );
      }

      case CUSTOMBUTTON: {
        return (
          <Button buttonType={'form'}
            className={`${field.customStyle} formCustomBtn`}
            onClick={field.onClick}>
            {field.fieldLabel.value}
          </Button>
        );
      }

      case COUNTER: {
        return (
          <Counter
            data={fieldProps}
            onClick={props.onClick}
            customStyle={field.customStyle}
          />
        )
      }

      default: {
        return null;
      }
    }
  };

  return (
    <Grid item className={classes.formWrapper}>
      {formTitle && renderTitle('h5', 'h5', formTitle)}
      {formFields && renderFormFields()}
    </Grid>
  );
};

export default RenderRFPForm;
