import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../../styles/themes/vad/constants';
import { borderRadius } from '../../../../../styles/themes/vad/borderRadius';

const useEventStyles = makeStyles(theme => ({
  collageType1: {
    display: 'flex',
    flex: 'auto',
    borderColor: theme.mixins.Grey(),
    ...theme.mixins.paddingLeft(theme.spacing(1)),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.paddingLeft(0),
      borderBottom: 'none',
    },
  },
  collageType1subHeading: {
    height: '90%',
    ...theme.mixins.borderRight(`1px solid ${theme.mixins.Grey(200)}`),
    backgroundColor: theme.mixins.White(),
    ...theme.mixins.marginRight(pxToRem(14)),
    [theme.breakpoints.up('lg')]: {
      ...theme.mixins.marginRight(0),
    },
  },
  cardDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '1px',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    '& .tagswrapper': {
      '&.calc-done': {
        '& .MuiChip-root': {
          '&:nth-last-child(2)': {
            minWidth: '10px',
          },
        },
      },
      '&.calc-done-last': {
        '& .MuiChip-root': {
          '&:last-child': {
            minWidth: '10px',
          },
        },
      },
      '& .MuiChip-root': {
        cursor: 'pointer',
        ...theme.mixins.marginRight('4px'),
        '&:last-child': {
          ...theme.mixins.marginRight('0'),
        },
      },
    },
  },
  eventDateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  eventDate: {
    color: theme.mixins.Black(),
  },
  eventMonth: {
    textTransform: 'uppercase',
  },
  collageType1Card: {
    color: theme.palette.text.tertiary,
    padding: `0 0 ${theme.spacing(2)}`,
    ...theme.mixins.paddingRight(theme.spacing(2)),
    [theme.breakpoints.up('lg')]: {
      padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  CategoryTag: {
    textTransform: 'uppercase',
    // This comments for Event page
    marginRight: '4px',
    marginLeft: 0,
    '& .MuiChip-label': {
      textOverflow: 'initial',
    },
    '&:hover': {
      background: theme.mixins.SeaGreen(100),
    },
  },
  linkRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: theme.mixins.White(),
    position: 'relative',
    borderBottom: ({ umbrellaECount }) =>
      umbrellaECount && `4px solid ${theme.mixins.DarkTurquoise()}`,
    '& .imageWrap': {
      '& img': {
        height: '100%',
        display: 'inline-block',
      },
    },
    '& [class^="MuiChip-root"]': {
      height: '24px',
    },
    '& .customLink': {
      '&::after': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        content: '""',
        top: '50%',
        left: '50%',
        transform: 'translate(-50% , -50%)'
      },
      '&:hover': {
        backgroundColor: theme.mixins.White(),
        '& h3': {
          color: theme.palette.common.SeaPort,
        },
        '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
          transform: 'scale3d(1.1, 1.1, 1.1)',
        },
        '& .icon img': {
          transform: 'none',
        },
      },
    },
    '&:hover': {
      backgroundColor: theme.mixins.White(),
      '& h3': {
        color: theme.palette.common.SeaPort,
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
      '& .icon img': {
        transform: 'none',
      },
    },
  },
  categoryOnImageWrapper: {
    display: 'flex',
    position: 'absolute',
    top: theme.spacing(2),
    ...theme.mixins.left(theme.spacing(3)),
  },
  categoryOnImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${pxToRem(4)} ${pxToRem(10)}`,
    borderRadius: borderRadius.b3,
    color: theme.mixins.White(),
    backgroundColor: theme.mixins.SeaGreen(),
    textTransform: 'uppercase',
  },
  umbrellaEventCountWrapper: {
    position: 'absolute',
    bottom: theme.spacing(3),
    left: theme.spacing(3),
  },
  umbrellaEventCount: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.mixins.SeaGreen(),
    height: pxToRem(65),
    width: pxToRem(65),
    '& p': {
      color: theme.mixins.milkWhite(),
    },
    '& span': {
      color: theme.mixins.White(),
    },
  },
  locationItem: {
    color: theme.palette.text.tertiary,
    marginBottom: pxToRem(12),
    ...theme.palette.common.ellipsis(2),
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
    },
    '& .location-icon': {
      color: theme.mixins.DarkTurquoise(),
      border: `2px solid ${theme.mixins.DarkTurquoise()}`,
      backgroundColor: theme.mixins.White(),
      width: pxToRem(35),
      height: pxToRem(35),
      '& svg': {
        width: pxToRem(21),
        height: pxToRem(16),
      },
    },
    '& .favourite-icon': {
      border: 'none',
      marginRight: 0,
      color: theme.mixins.Black(),
    },
  },
  timings: {
    marginBottom: pxToRem(10),
  },
}));

export default useEventStyles;