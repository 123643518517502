import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  const { left, right } = theme.mixins;
  return {
    root: {
      margin: '24px 0',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0 24px 0',
      },
      '& .MuiFormControl-root': {
        width: '100%',
        '& .MuiFormGroup-root': {
          '& .MuiFormControlLabel-root': {
            marginLeft: 0,
            marginBottom: '8px',
            minHeight: '56px',
            '&:last-child': {
              marginBottom: '0px',
            },
            [theme.breakpoints.down('sm')]: {
              minHeight: '48px',
            },
            '& .MuiButtonBase-root': {
              border: '1px solid #DDDEDE',
              borderRadius: '4px',
              marginBottom: '8px',
              padding: '15px',
              minHeight: '56px',
              [theme.breakpoints.down('sm')]: {
                minHeight: '48px',
                padding: '10px',
              },
              '&.Mui-checked': {
                backgroundColor: 'rgba(252, 239, 222, 0.2)',
                border: '1px solid #FF8707',
                '& .MuiIconButton-label': {
                  '& .radio-btn-Chekedicon': {
                    width: '24px',
                    height: '24px',
                    border: '1px solid #FF8707',
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    position: 'relative',
                    '&:before': {
                      width: '16px',
                      height: '16px',
                      position: 'absolute',
                      content: '""',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50% , -50%)',
                      background: '#FF8707',
                      borderRadius: '50%',
                    },
                  },
                },
              },
              position: 'absolute',
              left: 0,
              width: '100%',
              '& .MuiIconButton-label': {
                justifyContent: 'flex-start',
                '[dir="rtl"] &': {
                  // justifyContent: 'flex-end',
                },
                '& .radio-btn-icon': {
                  width: '24px',
                  height: '24px',
                  border: '1px solid #565A5C',
                  borderRadius: '50%',
                },
              },
            },
          },
        },
        '& .MuiTypography-body1': {
          zIndex: '9',
          width: '100%',
        },
        '& .MuiTypography-body2': {
          paddingLeft: '51px',
          '[dir="rtl"] &': {
            paddingLeft: '0',
            paddingRight: '36px',
          },
          fontSize: '16px',
          display: 'block',
          marginBottom: '7px',
        },
      },
    },
  };
});

export default useStyles;
