import React from 'react';
import { get } from 'lodash';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ButtonMUI from '../../Button';
import clsx from 'clsx';
import useStyles from './style';
import Typography from '../../Typography';
import RichText from '../../RichText';
import { getDictionaryText } from '../../../../utils/getDictionaryText';
import { shouldDisableUploadButton } from '../../../../utils/fileUploadValidations';

const InputFile = props => {
  const classes = useStyles(props);
  const {
    fieldLabel,
    selectedValue,
    fieldName,
    fieldType,
    error,
    uploadError,
    fileFormatValidationMessage,
    fileSizeValidationMessage,
    supportedDocMessage,
    disableFileInput,
  } = props.data;
  const {
    className,
  } = props;

  // renders typography based on type provided
  const renderText = (variant, type, value) => (
    <Typography
      variant={variant}
      component={type}
      gutterBottom
      className={classes.formHeading}
    >
      <Text field={value} />
    </Typography>
  );

  const calculateSize = size => {
    if ((size / 1048576).toFixed(2) > 1) {
      return `${(size / 1048576).toFixed(2)} mb`;
    } else if ((size / 1024).toFixed(2) > 1) {
      return `${(size / 1024).toFixed(2)} kb`;
    } else {
      return `${size} bytes`
    }
  }

  const renderErrorMessage = () => {
    let message = '';
    if (error === 'fileType') {
      message = get(fileFormatValidationMessage, 'fields.displayText', '');
    } else if (error === 'fileSize') {
      message = get(fileSizeValidationMessage, 'fields.displayText', '');
    }
    return renderText('body3', 'span', message);
  }
  return (<div className={clsx(classes.root, className)}>
    <div className={classes.inputLabel}>{renderText('body2Bold', 'span', fieldLabel)}</div>
    <label htmlFor={`input_file_${fieldName && fieldName.value}`}>
      <input
        style={{ display: 'none' }}
        id={`input_file_${fieldName && fieldName.value}`}
        name={fieldName && fieldName.value}
        onChange={props.onChange}
        type={fieldType && fieldType.value}
        multiple={props.shouldAllowMultipleUpload}
      />
      <ButtonMUI
        component="span"
        buttonType="primary"
        hasBorder={false}
        isStartIcon={false}
        disabled={disableFileInput || shouldDisableUploadButton(selectedValue && selectedValue.value)}
        className={classes.uploadButton}
      >
        {getDictionaryText('upload')}
      </ButtonMUI>
    </label>
    {selectedValue.value && selectedValue.value.length === 0 ? <div className={classes.noFileMessage}>{renderText('body2', 'span', { value: getDictionaryText('noFilesUploaded') })}</div> : ''}
    {error && <div className={classes.error}>{renderErrorMessage()}</div>}
    {uploadError && <div className={classes.error}>{renderText('body3', 'span', { value: uploadError })}</div>}
    {selectedValue.value && selectedValue.value.length ? <div className={classes.docsList}>
      {selectedValue.value.map((file, index) => {
        return <div key={index} className={classes.docsRow}>
          <div className={classes.fileName}>{renderText('body2', 'span', { value: file.name })}</div>
          <div className={classes.responsiveSection}>
            <div>{renderText('body2Bold', 'span', { value: calculateSize(file.size) })}</div>
            <div className={classes.removeLink} onClick={() => props.onRemoveClick(file.lastModified, fieldName.value)}>{renderText('body2Bold', 'span', { value: getDictionaryText('remove') })}</div>
          </div>
        </div>;
      })}
    </div> : ''}
    {supportedDocMessage && supportedDocMessage.value && <div className={classes.supportedDocsText}>
      <RichText
        innerHTML={supportedDocMessage}
        className="formSubmissionMessage"
      />
    </div>}
  </div>)
};

export default InputFile;