import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { isObject } from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import FormControl from '@material-ui/core/FormControl';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import get from 'lodash/get';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import { breakpoints } from '../../../../../styles/themes/vad/constants';
import useStyles from './Calendar.style';
import clsx from 'clsx';
import Typography from '../../Typography';
import { getYearsAddedToDate } from '../../../../utils/getYearsAddedToDate';

const variantType = null;
const renderText = text => (
  <Typography
    variant={variantType}
    component={variantType}
    gutterBottom={false}
  >
    <Text field={text} />
  </Typography>
);

const SingleDatePicker = props => {
  const classes = useStyles();
  const [isSingleCalendarOpen, setIsSingleCalendarOpen] = useState(false)
  const {
    startDate,
    startDateName,
    fieldLabel,
    validationMessage,
    showError,
    isRequired,
  } = props.data;
  let dateCalendar = '';
  const openDateCalendar = () => dateCalendar.setOpen(true);
  const onStartDateChange = (date, props) => {
    let target = {};
    const { data } = props;
    target.name = data && data.startDateName && data.startDateName.value;
    if (date) {
      target.value = date;
    } else {
      target.value = '';
    }
    props.onDateChange({ target: target });
  };

  const CustomInput = () => {
    return (
      <input
        id={startDateName && startDateName.value}
        name={startDateName && startDateName.value}
        className={classes.datePicker}
        label={fieldLabel && fieldLabel.value}
        value={
          startDate && startDate.value
            ? moment(startDate.value).format('YYYY-MM-DD')
            : ''
        }
      />
    );
  };
  return (
    <FormControl component="fieldset" className={classes.root}>
      <legend />
      <div className={classes.rangeCalendar}>
        <div className={clsx(classes.CalendarWrapper, 'calendarWrapper')}>
          <DatePicker
            onCalendarOpen={() => { setIsSingleCalendarOpen(true) }}
            onCalendarClose={() => { setIsSingleCalendarOpen(false) }}
            onChange={date => onStartDateChange(date, props)}
            startDate={startDate && startDate.value}
            endDate={startDate && startDate.value}
            className={classes.datePicker}
            placeholderText={fieldLabel && fieldLabel.value}
            id={startDateName && startDateName.value}
            name={startDateName && startDateName.value}
            ref={c => (dateCalendar = c)}
            customInput={<CustomInput />}
            popperPlacement={props.position}
            minDate={props.shouldSetMinDate ? new Date() : ''}
            maxDate={
              props.maxDateYearCount
                ? getYearsAddedToDate(new Date(), props.maxDateYearCount)
                : ''
            }
            {...props}
          />
          <CalendarTodayIcon className="calendarIcon" />
        </div>
        <div
          className={clsx(classes.rangeCalendarPicker, 'rangeCalendarPicker', `${isSingleCalendarOpen ? 'singleCalendarfocused' : ''}`)}
          onClick={() => openDateCalendar()}
        >
          {fieldLabel && (
            <label
              className={
                isObject(startDate && startDate.value)
                  ? classes.labelFilled
                  : 'default-shrink'
              }
              htmlFor={startDateName && startDateName.value}
            >
              <Typography
                variant={variantType}
                component={variantType}
                gutterBottom={false}
              >
                <Text field={{ value: fieldLabel.value }} />
              </Typography>
              {isRequired && isRequired.value && <span> *</span>}
            </label>
          )}
          {startDate && isObject(startDate.value) && (
            <Typography
              variant={variantType}
              component={variantType}
              gutterBottom={false}
            >
              <Text
                field={{
                  value: `${moment(startDate.value).format('DD/MM/YYYY')}`,
                }}
              />
            </Typography>
          )}
          {!startDate.value && fieldLabel && (
            <div className={`${classes.customPlaceholder} sty1`}>
              <Text field={fieldLabel} />
            </div>
          )}
          <CalendarTodayIcon className="calendarIcon" />
        </div>
      </div>
      {showError && (
        <div className={classes.errorMsg}>
          {renderText(
            validationMessage &&
            validationMessage.fields &&
            validationMessage.fields.displayText
          )}
        </div>
      )}
    </FormControl>
  );
};

const RangeDatePicker = props => {
  const classes = useStyles();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(true)
  const [calendarOpen, setCalendarOpen] = useState(1)

  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.lg}px)`);
  const {
    startDate,
    endDate,
    endDateName,
    startDateName,
    fieldLabel,
    validationMessage,
    showError,
    eventCalender,
    isRequired,
  } = props.data;

  var startDateCalendar = '';
  var endDateCalendar = '';
  // const openEndDateCalendar = () => endDateCalendar.setOpen(true);
  const openStartDateCalendar = () => startDateCalendar.setOpen(true);
  const onStartDateChange = (date, props) => {
    let target = {};
    const { data } = props;
    target.name = data && data.startDateName && data.startDateName.value;
    if (date) {
      target.value = date;
    } else {
      target.value = '';
    }
    props.onDateChange({ target: target });
    // openEndDateCalendar();
    setCalendarOpen(2)
    setIsEndDatePickerOpen(true)
  };
  const onEndDateChange = (date, props) => {
    let target = {};
    const { data } = props;
    target.name = data && data.endDateName && data.endDateName.value;
    if (date) {
      target.value = date;
    } else {
      target.value = '';
    }
    props.onDateChange({ target: target });
    setIsEndDatePickerOpen(false)
    setCalendarOpen(1)
  };
  const CustomInputStartDate = () => {
    return (
      <input
        id={startDateName && startDateName.value}
        name={startDateName && startDateName.value}
        className={classes.datePicker}
        label={fieldLabel && fieldLabel.value}
        value={
          startDate && startDate.value
            ? moment(startDate.value).format('YYYY-MM-DD')
            : ''
        }
      />
    );
  };
  const CustomInputEndDate = () => {
    return (
      <input
        id={endDateName && endDateName.value}
        name={endDateName && endDateName.value}
        className={classes.datePicker}
        label={fieldLabel && fieldLabel.value}
        value={
          endDate && endDate.value
            ? moment(endDate.value).format('YYYY-MM-DD')
            : ''
        }
      />
    );
  };

  const renderLabel = () => {
    const label = (
      <div className={classes.customPlaceholder}>
        <Text field={fieldLabel} />
      </div>
    );
    if (!eventCalender && !startDate.value) {
      return label;
    } else if (eventCalender && !startDate.value && !endDate.value) {
      return label;
    } else {
      return null;
    }
  };

  const startTimeExtraProps = {
    ...(props.shouldSetMinDate && { minDate: new Date() }),
    ...(props.maxDateYearCount && { maxDate: getYearsAddedToDate(new Date(), props.maxDateYearCount) }),
  }

  const endTimeExtraProps = {
    ...(startDate && startDate.value && { minDate: startDate.value }),
  }

  return (
    <FormControl
      component="fieldset"
      className={classes.root}
      aria-label={fieldLabel && fieldLabel.value}
    >
      <legend />
      <div className={classes.rangeCalendar}>
        <div
          className={clsx(classes.rangeCalendarPicker, 'rangeCalendarPicker')}
          onClick={() => openStartDateCalendar()}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              openStartDateCalendar()
            }
          }}
        >
          <label
            className={
              isObject(startDate.value) || isObject(endDate && endDate.value)
                ? classes.labelFilled
                : 'default-shrink'
            }
            htmlFor={startDateName && startDateName.value}
          >
            <Typography
              variant={variantType}
              component={variantType}
              gutterBottom={false}
            >
              <Text field={fieldLabel} />
            </Typography>
            {isRequired && isRequired.value && <span> *</span>}
          </label>
          {(isObject(startDate.value) || isObject(endDate.value)) && (
            <Typography
              variant={variantType}
              component={variantType}
              gutterBottom={false}
            >
              <Text
                field={{
                  value: `${isObject(startDate.value)
                    ? moment(startDate.value).format('DD/MM/YYYY')
                    : ''
                    } - ${isObject(endDate.value)
                      ? moment(endDate.value).format('DD/MM/YYYY')
                      : ''
                    }`,
                }}
              />
            </Typography>
          )}
          {renderLabel()}
          <CalendarTodayIcon className="calendarIcon" />
        </div>
        {calendarOpen === 1 && (
          <div className={clsx(classes.CalendarWrapper, 'calendarWrapper')}>
            <DatePicker
              onChange={date => onStartDateChange(date, props)}
              selectsStart
              startDate={startDate && startDate.value}
              endDate={endDate && endDate.value}
              className={classes.datePicker}
              monthsShown={isMobile ? 1 : 2}
              id={startDateName && startDateName.value}
              name={startDateName && startDateName.value}
              ref={c => (startDateCalendar = c)}
              popperPlacement={props.position}
              tabIndex={5}
              customInput={<CustomInputStartDate />}
              // minDate={props.shouldSetMinDate ? new Date() : ''}
              // maxDate={
              //   props.maxDateYearCount
              //     ? getYearsAddedToDate(new Date(), props.maxDateYearCount)
              //     : ''
              // }
              {...props}
              {...startTimeExtraProps}
            />
            <CalendarTodayIcon className="calendarIcon" />
          </div>
        )}
        {/* {startDate && ( */}
        {calendarOpen === 2 && (
          <div
            className={clsx(
              classes.CalendarWrapper,
              classes.endDate,
              'calendarWrapper'
            )}
          >
            <DatePicker
              open={isEndDatePickerOpen}
              onChange={date => onEndDateChange(date, props)}
              selectsEnd
              startDate={startDate && startDate.value}
              endDate={endDate && endDate.value}
              // minDate={startDate && startDate.value}
              className={classes.datePicker}
              ref={c => (endDateCalendar = c)}
              monthsShown={isMobile ? 1 : 2}
              id={endDateName && endDateName.value}
              name={endDateName && endDateName.value}
              popperPlacement={props.position}
              customInput={<CustomInputEndDate />}
              onClickOutside={() => {
                setIsEndDatePickerOpen(false)
                setCalendarOpen(1)
              }}
              {...props}
              {...endTimeExtraProps}
            />
            <CalendarTodayIcon className={classes.calendarIcon} />
          </div>
        )}
      </div>
      {showError && (
        <div className={classes.errorMsg}>
          {renderText(
            validationMessage &&
            validationMessage.fields &&
            validationMessage.fields.displayText
          )}
        </div>
      )}
    </FormControl>
  );
};

const Calender = props => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(`(max-width:${breakpoints.values.lg}px)`);
  let language = get(props, 'sitecoreContext.language', '');
  language = language && language.toLowerCase();

  return (
    <React.Fragment>
      {props.monthsShown === 2 || (props.data && props.data.eventCalender) ? (
        <RangeDatePicker
          monthsShown={
            isMobile ? 1 : props.monthsShown || props.data.monthsShown
          }
          data={props.data}
          isEventCalendar={props.data.eventCalender}
          onDateChange={props.onChange}
          position={language === 'ar' ? 'bottom-end' : 'bottom-start'}
          shouldSetMinDate={props.shouldSetMinDate}
          maxDateYearCount={props.maxDateYearCount}
        />
      ) : (
        <SingleDatePicker
          monthsShown={isMobile ? 1 : props.monthsShown}
          data={props.data}
          isEventCalendar={props.data.eventCalender}
          onDateChange={props.onChange}
          position={language === 'ar' ? 'bottom-end' : 'bottom-start'}
          shouldSetMinDate={props.shouldSetMinDate}
          maxDateYearCount={props.maxDateYearCount}
        />
      )}
    </React.Fragment>
  );
};

export default withSitecoreContext()(Calender);

