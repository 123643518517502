import React, { useEffect, useRef, useState, useMemo } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useStyles from './style';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { withNamespaces } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import Box from '../../../components/molecules/Box';
import Typography from '../../../components/atoms/Typography';
import RenderRFPForm from '../RequestForProposal/RenderRFPForm';
import {
  getLocalStorage,
  setLocalStorage,
} from './../../../../utils/localStorage';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { useMediaQuery } from '@material-ui/core';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import AddItinerary from './AddItinerary';
import { isEmpty } from 'lodash';
import { addItinerary } from '../../../containers/common/Filters/redux/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { useHistory } from "react-router-dom";
import { updateItineraryPlaceResult, toggleItineraryModal, isItineraryModalOpen } from '../ItineraryBuilderMap/redux/actions';
import { canUseDOM } from '../../../utils/canUseDOM';
import CustomToast from '../../../components/atoms/Toast';
import { showToast } from '../../../utils/showToast';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import get from 'lodash/get';
import { MAP_3D_TEMPLATE_ID } from '../../../../constants';

const ItineraryBuilder = props => {
  const {
    open,
    customClass,
    handleExit,
    itineraryExist = false,
    isCreateItinerary = false,
    itemUniqueKey = { itemUniqueKey },
    prefilledFields = null,
    navigateOnSubmit = false,
    sitecoreContext,
    handleSubmitBtn = () => { }
  } = props;

  // 3D Map Page Template Id
  const isMap3DPage = get(sitecoreContext, 'route.templateId', '') === MAP_3D_TEMPLATE_ID
  const isItineraryPage = get(sitecoreContext, 'route.templateName', '') === 'Itinerary Map'
  const classes = useStyles();
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const formRef = useRef(null);
  const history = useHistory()

  let itineraryList = JSON.parse(getLocalStorage('itineraryList') || '[]');
  const [isNewItinerary, setIsNewItinerary] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const itineraryDataRef = useRef(null);
  const { CTALink, itineraryMapCTA } = useSelector(state => state.FilteredDataReducer);

  useEffect(() => {
    itineraryDataRef.current = { itineraryList, prefilledFields };
  }, [itineraryList, prefilledFields])

  const longDurationCounter = {
    id: '3',
    fields: {
      fieldLabel: {
        value: getDictionaryText('LongStayDuration'),
      },
      customStyle: 'hide-counter',
      incrementorBtnDisable: 21,
      decrementorBtnDisable: 4,
      validationMessage: {
        value: getDictionaryText('MaxDaysLimit'),
      },
      fieldType: {
        value: 'Counter',
      },
      fieldName: {
        value: 'long-stay-duration',
      },
    },
  };

  const formFields = useMemo(() => ({
    fields: [
      {
        id: '1',
        fields: {
          fieldGroup: [
            {
              id: 'b5ecf150-ea73-4f32-81ed-9e222bd86846',
              fields: {
                fieldLabel: { value: getDictionaryText('NameFieldLabel') },
                customStyle: 'customInput',
                fieldType: { value: 'SingleLineText' },
                placeholderText: {
                  value: getDictionaryText('NameFieldPlaceholder'),
                },
                fieldName: { value: 'trip_name' },
                extraProps: { inputProps: { maxLength: 50 } }
              },
            },
            {
              id: '36c5db2d-296e-4f23-b9d8-00055b58e230',
              fields: {
                fieldLabel: { value: getDictionaryText('DateFieldLabel') },
                fieldType: { value: 'Date' },
                placeholderText: {
                  value: getDictionaryText('DateFieldPlaceholder'),
                },
                fieldName: { value: 'trip_date' },
              },
            },
          ],
        },
      },
      {
        id: '2',
        fields: {
          fieldLabel: {
            value: getDictionaryText('TripDuration'),
          },
          customStyle: 'customRadio',
          listItems: [
            {
              id: '7426d374-3c28-4be6-8749-2eb870c0bf11',
              fields: {
                displayText: {
                  value: getDictionaryText('DurationOption1'),
                },
                key: {
                  value: '1',
                },
              },
            },
            {
              id: '4a631c33-7114-4916-8339-6c7fad583702',
              fields: {
                displayText: {
                  value: getDictionaryText('DurationOption2'),
                },
                key: {
                  value: '2',
                },
              },
            },
            {
              id: '4a631c33-7114-4916-8339-6c7fad583552',
              fields: {
                displayText: {
                  value: getDictionaryText('DurationOption3'),
                },
                key: {
                  value: '3',
                },
              },
            },
            {
              id: '4a631c33-7114-4916-8339-6c7fad566702',
              fields: {
                displayText: {
                  value: getDictionaryText('DurationOption4'),
                },
                key: {
                  value: '4',
                },
              },
            },
          ],
          fieldType: {
            value: 'RadioButtonList',
          },
          isRequired: {
            value: false,
          },
          validationMessage: null,
          fieldName: {
            value: 'trip_days',
          },
        },
      },
      ...(prefilledFields && prefilledFields.trip_days > 3 ? [longDurationCounter] : []),
      {
        id: '4',
        fields: {
          fieldGroup: [
            {
              id: '1',
              fields: {
                fieldType: {
                  value: 'Submit',
                },
                fieldLabel: {
                  value: prefilledFields ? getDictionaryText('SaveUpdates') : getDictionaryText('BuildItinerary'),
                },
                customStyle: isMobile && !prefilledFields ? 'create-itinerary-submit-btn-alignment' : '',
                size: 'small',
              },
            },
            ...(prefilledFields ? [{
              id: '2',
              fields: {
                fieldType: {
                  value: 'CustomButton',
                },
                fieldLabel: {
                  value: getDictionaryText('DeleteItinerary'),
                },
                customStyle: 'deleteCta',
                size: 'small',
                btnType: 'button',
                onClick: () => { handleDeleteItinerary() }
              },
            }] : []),
          ],
        },
      },
    ],
  }), [prefilledFields, isMobile]);

  const [formDataList, setFormDataList] = useState({
    'long-stay-duration': 4,
    'trip_days': '1',
    'trip_name': '',
    'trip_date': '',
  });

  const isMaxWidth = useMediaQuery(`(max-width: ${374}px)`);

  const shortDurationSelected = formDataList['trip_days'] < 4;

  useEffect(() => {
    if (!prefilledFields) return
    const { trip_name, trip_days, trip_date } = prefilledFields
    const tripDays = trip_days > 3 ? 4 : trip_days
    const longTripDays = trip_days < 4 ? 4 : trip_days
    setFormDataList({
      trip_name,
      'trip_days': tripDays.toString(),
      'trip_date': trip_date && moment(trip_date) || '',
      'long-stay-duration': longTripDays
    })
  }, [prefilledFields])

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'crossBtn':
        return (
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L21 21M1 21L21 1"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      case 'leftArrow':
        return (
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.7599 0L-9.82285e-05 6L5.7599 12L6.57206 11.154L2.20598 6.6H14.3999V5.4H2.20598L6.57206 0.846L5.7599 0Z" fill="#898C8D" />
          </svg>
        )
      default:
        return <></>;
    }
  };

  const resetFields = () => {
    if (!prefilledFields) {
      setFormDataList({
        'long-stay-duration': 4,
        'trip_days': '1',
        'trip_name': '',
        'trip_date': '',
      })
      if (Number(formDataList['trip_days']) === 4) {
        showLongStayCounter('remove')
      }
    }
    else if (prefilledFields) {
      const { trip_name, trip_days, trip_date } = prefilledFields
      const tripDays = trip_days > 3 ? 4 : trip_days
      const longTripDays = trip_days < 4 ? 4 : trip_days
      const found = formFields.fields.find(x => x.fields?.fieldName?.value === 'long-stay-duration')
      if (trip_days > 3) showLongStayCounter('add');
      else if (trip_days < 4 && found) showLongStayCounter('remove')
      setFormDataList({
        trip_name,
        'trip_days': tripDays.toString(),
        'trip_date': trip_date && moment(trip_date) || '',
        'long-stay-duration': longTripDays
      })
    }
  }


  const showLongStayCounter = field => {
    let flag = false
    if (field === 'add') {
      formFields.fields.forEach(val => {
        if (val.fields?.fieldType?.value === 'Counter') flag = true
      })
    }
    if (flag) return

    const length = formFields.fields.length;
    if (field === 'add') {
      formFields.fields.splice(length - 1, 0, longDurationCounter);
    } else {
      formFields.fields.splice(length - 2, 1);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'trip_days') {
      if (Number(value) === 4 && formDataList['trip_days'] < 4) {
        showLongStayCounter('add');
      } else if (Number(value) < 4 && Number(formDataList['trip_days']) === 4) {
        showLongStayCounter('remove');
      }
    }
    setFormDataList({
      ...formDataList,
      [name]: value,
    });
  };

  const handleClick = (name, value) => {
    const countervalue = name === 'increment' ? value + 1 : value - 1;

    setFormDataList(prevState => ({
      ...prevState,
      'long-stay-duration': countervalue,
    }));
  };

  function getRandomId() {
    return Math.floor(Math.random() * 1000000) + 1;
  }

  const CTALinkOn3DMap = (id) => {
    const VADStaticRoute = get(sitecoreContext, 'VADStaticRoute', '')
    const placeholders = get(VADStaticRoute, 'sitecore.route.placeholders', '');
    const vadHeader = get(placeholders, 'vad-header', []);
    const favouriteBasket = vadHeader.find(
      item => item.componentName === 'FavoriteBasket'
    );
    return `${window.location.origin}${favouriteBasket?.fields?.itineraryMapCTA?.value?.href}?itineraryId=${id}`;
  }

  const defaulttripNumer = useMemo(() => {
    if (!itineraryList) return null
    const filteredItineraryList = itineraryList.filter(x => x['trip_name'].includes('My Trip')) || [];
    if (filteredItineraryList.length) {
      const split = filteredItineraryList[filteredItineraryList.length - 1]['trip_name'].split(' ')
      return split.length === 4 ? (parseInt(split[3]) + 1) : 1
    }
  }, [itineraryList])

  const getSerialNumber = (number) => {
    if (!number) return '';

    if (number <= 9) return `- 0${number.toString()}`

    return `- ${number.toString()}`
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(isItineraryModalOpen(false))
    let formData = {
      id: getRandomId().toString(),
      'trip_days': 1,
      'trip_name': `My Trip${defaulttripNumer ? ' ' : ''}${getSerialNumber(defaulttripNumer)}`,
      'trip_date': '',
    };

    const elements = Array.from(formRef.current.elements);
    elements.forEach(element => {
      const { name, value, checked } = element;
      if (isEmpty(value)) return
      if (name === 'trip_days') {
        if (!checked) return;
        const days =
          Number(value) === 4
            ? formDataList['long-stay-duration']
            : Number(value);
        formData[name] = days;
      }
      else if (name) {
        formData[name] = value;
      }
    });

    const noOfDays = Number(formData['trip_days'])
    let days = {}
    for (let index = 0; index < noOfDays; index++) {
      days = { ...days, [index + 1]: [] }
    }

    const tripDate = formData?.trip_date
    const tripDays = formData?.trip_days
    const currentDate = !isEmpty(tripDate) ? moment(tripDate) : null;
    let newdays = {};
    for (let i = 0; i < tripDays; i++) {
      const date = currentDate ? currentDate.clone().add(i, 'days').format('YYYY-MM-DD') : `Day-${i + 1}`
      // newdays = { ...newdays, [date]: [] }
      newdays = { ...newdays, [date]: placeId && i === 0 ? [placeId] : [] };
    }

    formData = { ...formData, newdays }

    let result = []
    if (isEmpty(prefilledFields)) {
      // Create Itinerary
      result = [...itineraryList, formData];
    }
    else {
      // Edit Itinerary
      const { id } = prefilledFields
      const indexNo = itineraryList.findIndex(o => o.id === id)
      result = [...itineraryList]
      const editResult = editItinerary(formData)
      result.splice(indexNo, 1, editResult.formData);

      // For Redux
      const apiDataItems = [...itineraryList]
      apiDataItems.splice(indexNo, 1, editResult.rawformData);
      dispatch(updateItineraryPlaceResult(apiDataItems))
    }

    setPlaceId(null)
    setLocalStorage('itineraryList', JSON.stringify(result));
    dispatch(addItinerary(result));
    handleExit();
    setIsNewItinerary(false);

    // setFormDataList({
    //   'long-stay-duration': 4,
    //   'trip_days': '1',
    //   'trip_name': '',
    //   'trip_date': '',
    // })

    if (formData.trip_days > 3) {
      const length = formFields.fields.length;
      formFields.fields.splice(length - 2, 1);
    }
    if (navigateOnSubmit && canUseDOM && !isItineraryPage && !isMap3DPage) {
      const redirectURL = `${window.location.origin}${itineraryMapCTA?.value?.href}?itineraryId=${formData?.id}`;
      window.location.href = redirectURL;
    } else if (isMap3DPage) {
      window.open(CTALinkOn3DMap(formData?.id), '_blank');
    }
    dispatch(toggleItineraryModal(false))
    handleSubmitBtn(formData)
  };

  const customStyles = {
    backdrop: {
      backgroundColor: isMobile ? 'rgba(255, 255, 255 , 1)' : 'rgba(0, 0, 0, 0.3)',
      zIndex: -1,
      position: 'fixed',
      inset: 0,
    },
  };
  const showCrossButton = (handleExit, setIsNewItinerary) => {
    return (
      <Box className="crossWraper">
        <span
          onClick={() => {
            if (isNewItinerary) {
              setIsNewItinerary(false)
            } else {
              dispatch(toggleItineraryModal(false))
              dispatch(isItineraryModalOpen(false))
              handleExit();
              setIsNewItinerary(false);
              resetFields()
            }
          }}
        >
          {isCreateItinerary || isNewItinerary ? (
            <>
              {createSVG('leftArrow')}
              <span className='text'>{getDictionaryText('ItineraryBack')}</span>
            </>
          )
            :
            createSVG('crossBtn')}
        </span>
      </Box>
    )
  }

  const editItinerary = (formData) => {
    const { newdays, trip_days } = prefilledFields
    const updatedKeys = Object.keys(formData.newdays);
    const prefilledKeys = Object.keys(newdays);

    const handleFormData = (condition) => {
      switch (condition) {
        case '1':
          for (let i = 0; i < prefilledKeys.length; i++) {
            if (updatedKeys[i]) {
              formData['newdays'][updatedKeys[i]] = newdays[prefilledKeys[i]];
            } else {
              formData.newdays[updatedKeys[updatedKeys.length - 1]] = [
                ...formData.newdays[updatedKeys[updatedKeys.length - 1]],
                ...newdays[prefilledKeys[i]]
              ];
              if (formData.newdays[updatedKeys[updatedKeys.length - 1]].length > 10) {
                formData.newdays[updatedKeys[updatedKeys.length - 1]] = formData.newdays[updatedKeys[updatedKeys.length - 1]].slice(0, 10)
              }
            }
          }
          break;

        case '2':
          for (let i = 0; i < prefilledKeys.length; i++) {
            if (updatedKeys[i]) {
              formData['newdays'][updatedKeys[i]] = newdays[prefilledKeys[i]];
            }
          }
          break;
        default:
          break;
      }
    };

    if (formData.trip_days > trip_days) {
      handleFormData('2')
    }
    else if (formData.trip_days <= trip_days) {
      handleFormData('1')
    }

    props.updateItineraryItems(formData)
    const rawformData = { ...formData, newdays: formData['newdays'], id: prefilledFields.id };

    const formattedResult = {};
    Object.keys(formData.newdays).map(date => {
      formattedResult[date] = formData['newdays'][date]?.map(item => item.itemUniqueKey) || [];
    });

    formData = { ...formData, newdays: formattedResult, id: prefilledFields.id };

    return {
      formData, rawformData
    }
  }

  const handleDeleteItinerary = () => {
    const { itineraryList, prefilledFields } = itineraryDataRef.current || {}
    const oldItineraryList = [...itineraryList]
    const result = itineraryList.filter(val => val.id !== prefilledFields.id)
    setIsDelete(true)
    dispatch(addItinerary(result));
    setLocalStorage('itineraryList', JSON.stringify(result));

    const handleRemoveItineraryUndo = () => {
      dispatch(addItinerary(oldItineraryList));
      setLocalStorage('itineraryList', JSON.stringify(oldItineraryList));
      toast.dismiss()
    }

    const onCloseCallback = () => {
      if (canUseDOM) {
        const redirectURL = `${window.location.origin}${CTALink?.value?.href}`;
        window.location.href = redirectURL;
      }
    }

    showToast(
      <CustomToast
        // ellipseFix={'noEllipse'}
        truncateLimit={30}
        textTitle={getDictionaryText('ItineraryRemoved')}
        showLinkLabel={true}
        linkLabel={getDictionaryText('Undo')}
        onClick={() => handleRemoveItineraryUndo()}
      />
      , isRTL,
      onCloseCallback
    )

  }

  const renderModalContent = () => {
    return (
      <>
        <Box className={`boxWrapper sty2 ${isDelete ? 'disable-cta' : ''}`}>
          {isCreateItinerary || isNewItinerary || !itineraryExist ? (
            <>
              {showCrossButton(handleExit, setIsNewItinerary)}
              <Box
                className={`formWrapper ${shortDurationSelected ? 'itinerary-alignment' : ''
                  } ${isMaxWidth ? 'labelAlingment' : ''}`}
              >
                <form onSubmit={handleSubmit} ref={formRef}>
                  <Typography variant={'h4'} component={'span'}>
                    <Text field={{ value: prefilledFields ? getDictionaryText('EditCustomItinerary') : getDictionaryText('Title') }} />
                  </Typography>
                  <RenderRFPForm
                    formData={formFields}
                    formDataList={formDataList}
                    onChange={handleChange}
                    onClick={handleClick}
                    isFormValid={true}
                  // errors={errors}
                  />
                </form>
              </Box>
            </>
          ) : (
            <>
              {showCrossButton(handleExit, setIsNewItinerary)}
              <AddItinerary
                itemUniqueKey={itemUniqueKey}
                setIsOpen={e => props.setIsOpen(e)}
                createNewItinerary={(e, id) => {
                  props.setIsOpen(e);
                  setIsNewItinerary(e);
                  setPlaceId(id)
                }}
              />
            </>
          )}
        </Box>
      </>
    );
  };

  return (
    <Modal
      id='ItineraryModal'
      BackdropProps={{ style: customStyles.backdrop }}
      className={`${classes.root} itineraryModal ${isMobile ? 'itineary-modal-mob' : ''} ${isNewItinerary ? 'createItinerary' : customClass}`}
      open={open}
      onClose={() => {
        dispatch(isItineraryModalOpen(false))
        resetFields()
        handleExit()
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(ItineraryBuilder))
);
