import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from '../../../../styles/themes/vad/borderRadius';
import { pxToRem } from '../../../../styles/themes/vad/constants';

export default makeStyles(theme => ({
  languageSelectorBtn: {
    cursor: 'pointer',
    flexGrow: 1,
    display: 'flex',
    position: 'relative',
    border: '2px solid',
    borderRadius: borderRadius.b24Rem,
    transition: 'background-color 0.3s ease-in-out',
    padding: `${pxToRem(14)} ${theme.spacing(3)}`,
    ...theme.mixins.paddingLeft(theme.spacing(6)),
    '@media (min-width: 1024px) and (max-width: 1599px)': {
      padding: `12px ${pxToRem(20)}`,
      // ...theme.mixins.paddingLeft(theme.spacing(6)),
    },
    '& svg': {
      position: 'absolute',
      top: `calc(50% - ${theme.spacing(1.5)})`,
      ...theme.mixins.left(theme.spacing(2)),
      '@media (min-width: 1024px) and (max-width: 1599px)': {
        display: 'none',
      },
    },
    '&:hover': {
      backgroundColor: theme.mixins.Black(200),
    },
  },
  popupBox: {
    padding: '0 !important',
  },
  languageSelector: {
    marginTop: theme.spacing(2),
    boxShadow: theme.shadows[1],
    borderRadius: borderRadius.b3,
    backgroundColor: theme.mixins.White(),
    padding: `${theme.spacing(4)} 0`,
    display: 'flex',
    width: '100%',
    '& .MuiList-padding': {
      padding: 0,
      width: pxToRem(300),
    },
    '& li': {
      cursor: 'pointer',
      padding: 0,
      '& a': {
        width: '100%',
        textAlign: theme.mixins.textLeft(),
        padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
        [theme.breakpoints.up('lg')]: {
          display: 'flex',
          alignItems: 'center',
          '& span': {
            ...theme.mixins.paddingRight(theme.spacing(4)),
          },
        },
        // '&:focus': {
        //   outline: '2px solid #000',
        // },
      },
      '&.selected': {
        backgroundColor: theme.mixins.Black(30),
        position: 'relative',
      },
      '& svg': {
        ...theme.mixins.right(pxToRem(40)),
        position: 'absolute',
      },
      '&:hover': {
        backgroundColor: theme.mixins.Black(30),
      },
    },
  },
  '@global': {
    '#language-selector-popup': {
      zIndex: 1400,
    },
  },
}));
