import { Box, Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useStyles from './style';
import Typography from '../Typography';
import Link from '../../../components/atoms/Link';
import { isItineraryExist } from './../../../../utils/utility';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import ellipsisText from '../../../utils/ellipsisText';
import ButtonMUI from '../Button';
import { isItineraryModalOpen } from '../../../containers/common/ItineraryBuilderMap/redux/actions';
import { useDispatch } from 'react-redux';

const CustomToast = (props) => {
  const { linkLabel, textTitle = '', showLinkLabel = true, ellipseFix = '', truncateLimit = 0, handleClose } = props
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const dispatch = useDispatch();
  let itineraryList = isItineraryExist()
  const renderLinkLabel = () => {
    return linkLabel || getDictionaryText(itineraryList ? 'AddToItinerary' : 'Create')
  }

  const renderText = () => {
    if (truncateLimit > 0) {
      return ellipsisText(textTitle, truncateLimit)
    }
    return textTitle
  }

  const onClickHandler = (e) => {
    e.preventDefault()
    dispatch(isItineraryModalOpen(true))
    props.onClick(e)
  }
  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'arrowIcon':
        return (
          <svg className={`${customClass} customSVG`} width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.64 0L14.4 6L8.64 12L7.82784 11.154L12.1939 6.6H0V5.4H12.1939L7.82784 0.846L8.64 0Z" fill={fill} />
          </svg>
        )
        break;
      case 'cross':
        return (
          <svg className={`${customClass} customSVG`} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L10 10M2 10L10 2" stroke="#565A5C" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        )
        break;
      default:
        return <></>
    }
  }
  let duration = 3000
  const [count, setCount] = useState(100);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const intervalTime = isMobile ? duration / 100 : duration / 80; // Calculate the interval time based on duration
    let interval;

    if (!isPaused) {
      interval = setInterval(() => {
        setCount(prevCount => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(interval);
            return prevCount;
          }
        });
      }, intervalTime);
    }

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [duration, isPaused]);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <Box className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Box className='toastWrapper'>
        <Box className='contentWrapper'>
          <Box className='innerWrapper'>
            <Button onClick={() => { handleClose() }} className='crossButton'>{createSVG('cross')} <CircularProgress variant="determinate" value={count} /></Button>
            <Typography className={ellipseFix} variant="body2">{renderText()}</Typography>
          </Box>
          {showLinkLabel && !linkLabel &&
            <ButtonMUI
              type="button"
              buttonType="form"
              size="small"
              tabIndex={0}
              hasBorder={false}
              className="toast-btn"
              onClick={(e) => onClickHandler(e)}
              endIconSvg={createSVG('arrowIcon', '#FF8707', '')}
            >
              {renderLinkLabel()}
            </ButtonMUI>

          }
          {showLinkLabel && linkLabel &&
            <Link
              onClick={(e) => onClickHandler(e)}
              isCustom={true}
              className="link"
            >
              {createSVG('arrowIcon', '#000000', '')}
              <Typography variant="body2Bold">{renderLinkLabel()}</Typography>
            </Link>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default CustomToast