import React, { useState } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from './style';
import Typography from '../../../Typography';
import { isEmpty } from 'lodash';
import { getDictionaryText } from '../../../../../utils/getDictionaryText';

const RadioType2 = (props) => {
  const {
    list,
  } = props;

  let recentItem = !isEmpty(list) && list[list?.length - 1];
  const { trip_name, id } = recentItem
  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    const val = event.target.value
    setValue(val);
    props.selectedVal(val ? recentItem : null)
  };

  const classes = useStyles();

  const renderRadioOptions = (label, val = '') => {
    return (
      <FormControlLabel
        value={val}
        control={
          <Radio
            checkedIcon={<span className={'radio-btn-Chekedicon'} />}
            icon={<span className={'radio-btn-icon'} />}
            disableRipple
          />
        }
        label={label}
      />
    );
  };

  const renderLabel = (label) => {
    return (
      <Typography variant={'body2'} component={'span'} gutterBottom={false}>
        {getDictionaryText(label)}
      </Typography>
    );
  };

  const radioOptionOneLabel = renderLabel('CreateNew')
  const radioOptionTwoLabel = renderLabel(`${getDictionaryText('AddPlaceTo')} "${trip_name}"`)


  return (
    <div className={classes.root}>  <FormControl>
      <RadioGroup className='radioGroup' value={value} onChange={handleChange} defaultValue={null}>
        {recentItem && renderRadioOptions(radioOptionOneLabel)}
        {recentItem && renderRadioOptions(radioOptionTwoLabel, id)}
      </RadioGroup>
    </FormControl></div>
  )
}

export default RadioType2