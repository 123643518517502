import React from 'react';
import Typography from '../../../atoms/Typography';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import CustomLink from '../../../atoms/Link';
import PropTypes from 'prop-types';
import CustomAvatar from '../../../atoms/Avatar';
import Box from '../../../molecules/Box';
import Picture from '../../../atoms/Picture';
import useEventStyles from './style';
import aspectRatios from '../../../../utils/AspectRatios';
import Favorite from '../../../atoms/Favorite';
import moment from 'moment';
import { getUTCDateInUAEFormat, formatArabicLangMonth } from '../../../../utils/dateTimeFunctions/dateTimeFunctions';
import { useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
const EventVariationTwo = (props) => {
  const {
    title,
    desktopImage,
    mobileImage,
    ctaLink,
    itemUniqueKey,
    componentName,
    imageCtaUrl,
    isShowFavorite,
    showFavoriteIcon,
    eventCategories,
    isAdCard,
    other,
  } = props
  const {
    startDate,
    endDate,
    locationTitle,
    region,
    eventType,
    openTime,
    closeTime,
    umbrellaEventCount,
    pageContentType,
    forcedRatio,
    lazyLoading = false,
  } = other;
  let umbrellaECount = umbrellaEventCount;
  if (umbrellaEventCount > 0 && umbrellaEventCount < 9) {
    umbrellaECount = `0${umbrellaEventCount}`;
  } else if (umbrellaEventCount === 0) {
    umbrellaECount = '';
  }
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';
  const startDateMonth = startDate ? getUTCDateInUAEFormat(startDate, 'MMM') : '';
  const startDateDay = startDate ? getUTCDateInUAEFormat(startDate, 'DD') : '';
  const startTime = startDate ? getUTCDateInUAEFormat(startDate, 'hh:mm A') : '';
  const endDateMonth = endDate ? getUTCDateInUAEFormat(endDate, 'MMM') : '';
  const endDateDay = endDate ? getUTCDateInUAEFormat(endDate, 'DD') : '';
  const endTime = endDate ? getUTCDateInUAEFormat(endDate, 'hh:mm A') : '';

  const formatedstartDateMonth = formatArabicLangMonth(startDate)
  const formatedendDateMonth = formatArabicLangMonth(endDate)

  let endDateFormatted = endDateDay;
  if (startDateMonth !== endDateMonth) {
    endDateFormatted = `${isRTL ? formatedendDateMonth : endDateMonth} ${endDateDay}`;
  }

  const eventStyle = useEventStyles({ umbrellaECount, pageContentType }) || {};

  //NOTE - This is a quick fix due to non-iterable spreading issue
  let categoryTags = !isEmpty(ctaLink) ? [...ctaLink] : [];
  if (eventType && eventType.isPaid) {
    if (categoryTags && categoryTags.length > 0) {
      categoryTags[1] = eventType.isPaid;
    } else {
      categoryTags[0] = eventType.isPaid;
    }
  }
  if (Array.isArray(other.eventCategories) && other.eventCategories.length > 0) {
    categoryTags = categoryTags.concat(other.eventCategories);
  }

  const displayTimeLabel = () => {
    const date = `${isRTL ? formatedstartDateMonth : startDateMonth} ${startDateDay} - ${endDateFormatted}`
    const time = isRTL ? closeTime + ' - ' + openTime : `${startTime} - ${endTime}`
    return `${date}, ${time}`
  }

  return (
    <React.Fragment>
      {showFavoriteIcon &&
        <Favorite ariaLabel={title} itemUniqueKey={itemUniqueKey} isShowFavorite={isShowFavorite} componentName={componentName} />
      }
      <Box
        // link={{ value: { href: imageCtaUrl } }}
        className={eventStyle.linkRoot}
      >
        <Box className={'eventImageWrapper imageWrap'}>
          <Picture
            media={{
              image: desktopImage,
              mobileImage: mobileImage,
              disableLazyLoad: true,
              aspectRatio: aspectRatios['3x2-card'],
              forcedRatio,
            }}
            alternateAltText={title && title.value}
          />
          {
            <Box className={`${eventStyle.categoryOnImageWrapper} tagWrapper`}>
              {isAdCard ? (
                eventCategories.map((val, index) => (
                  <Typography
                    variant="labelBold"
                    component="span"
                    className={`${eventStyle.categoryOnImage} ${index % 2 !== 1 ? 'blackLabel' : ''}`}
                    key={index}
                  >
                    {val}
                  </Typography>
                ))
              ) : (
                eventCategories && (
                  <Typography
                    variant="labelBold"
                    component="span"
                    className={eventStyle.categoryOnImage}
                  >
                    {eventCategories}
                  </Typography>
                )
              )}


            </Box>
          }
          {umbrellaECount && (
            <Box className={eventStyle.umbrellaEventCountWrapper}>
              <CustomAvatar className={eventStyle.umbrellaEventCount}>
                <Typography component="span" variant="body2Bold">
                  <Text field={{ value: umbrellaECount }} />
                </Typography>
                <Typography component="p" variant="body2Bold">
                  <Text field={{ value: 'Events' }} />
                </Typography>
              </CustomAvatar>
            </Box>
          )}
        </Box>
        <Box className={`${eventStyle.eventCarouselContent} calenderType`}>
          <Typography component="p" variant="labelBlack" className="eventName">
            <Text field={{ value: displayTimeLabel() }} />
          </Typography>
          <CustomLink className={'customLink'} link={{ value: { href: imageCtaUrl } }} draggable={false}>
            <Typography component="p" variant="h5Bold" className="eventTitle">
              <Text field={{ value: title }} />
            </Typography>
          </CustomLink>
          <Typography component="p" variant="body3Bold" className="eventLocation">
            <Text field={{ value: [locationTitle, region].filter(Boolean).join(', ') }} />
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};

EventVariationTwo.propTypes = {
  title: PropTypes.string,
  desktopImage: PropTypes.object,
  mobileImage: PropTypes.object,
  ctaLink: PropTypes.array,
  imageCtaUrl: PropTypes.string,
  cardType: PropTypes.string,
};

EventVariationTwo.defaultProps = {
  title: '',
  desktopImage: {},
  mobileImage: {},
  ctaLink: [],
  imageCtaUrl: '',
  cardType: '',
};

export default withSitecoreContext()(withNamespaces()(EventVariationTwo));
