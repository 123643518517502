import React, { useState } from 'react';
import {
  getLocalStorage,
  setLocalStorage,
} from './../../../../utils/localStorage';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import Link from '../../../components/atoms/Link';
import Typography from '../../../components/atoms/Typography';
import Button from '../../../components/atoms/Button';
import RadioType2 from '../../../components/atoms/Forms/Radio/RadioType2';
import Box from '../../../components/molecules/Box';
import { showToast } from '../../../utils/showToast';
import CustomToast from '../../../components/atoms/Toast'
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import { isItineraryModalOpen } from '../../../containers/common/ItineraryBuilderMap/redux/actions'

const AddItinerary = (props) => {
  const { itemUniqueKey } = props
  const [selectedVal, setSelectedVal] = useState('')
  const isMobile = useMediaQuery(`(max-width:${1023}px)`);
  const dispatch = useDispatch();
  let itineraryList = JSON.parse(getLocalStorage('itineraryList') || '[]')
  const { CTALink } = useSelector(state => state.FilteredDataReducer);

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'arrowIcon':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
            <path d="M8.64 0L14.4 6L8.64 12L7.82784 11.154L12.1939 6.6H0V5.4H12.1939L7.82784 0.846L8.64 0Z" fill="white" />
          </svg>
        )
        break;
      default:
        return <></>
    }
  }

  let newPlaceId = itemUniqueKey
  const theme = useTheme();
  const isRTL = theme && theme.direction === 'rtl';

  const addPlaceToLastDay = () => {
    dispatch(isItineraryModalOpen(false))
    const selectedItinerary = selectedVal;
    let toastMessage = ''
    let placesLimitExceeds = false

    if (selectedItinerary) {
      const lastObject = itineraryList[itineraryList.length - 1];
      // const daysArray = lastObject['days'];
      const daysArray = lastObject['newdays'];
      // const lastDay = Object.keys(daysArray).length;
      let lastDay = Object.keys(daysArray || {});
      lastDay = lastDay[lastDay.length - 1];
      if (daysArray[lastDay].length >= 10) {
        toastMessage = getDictionaryText('10DaysLimit')
        placesLimitExceeds = true
      }
      else {
        daysArray[lastDay].push(newPlaceId);
        daysArray[lastDay] = Array.from(new Set(daysArray[lastDay]));
        setLocalStorage('itineraryList', JSON.stringify(itineraryList));
        toastMessage = getDictionaryText('SavedTo')
        placesLimitExceeds = false
      }
    }
    props.setIsOpen(false)
    props.createNewItinerary(false)

    const handleUndoPlaces = () => {
      dispatch(isItineraryModalOpen(false))
      const id = selectedItinerary.id
      const updatedResult = [...itineraryList]
      let found = updatedResult.find(x => x.id === id)
      const indexNo = updatedResult.findIndex(o => o.id === id)
      const keys = Object.keys(found.newdays);
      const lastKey = keys[keys.length - 1];
      const length = found.newdays[lastKey].length - 1
      const updateDays = {
        ...found,
        newdays: {
          ...found.newdays,
          [lastKey]: found['newdays'][lastKey].slice(0, length)
        }
      };

      found = { ...found, newdays: updateDays['newdays'] }
      updatedResult.splice(indexNo, 1, found);
      setLocalStorage('itineraryList', JSON.stringify(updatedResult));
      toast.dismiss()
    }


    showToast(
      <CustomToast
        truncateLimit={isRTL && isMobile && 15 || 70}
        textTitle={`${toastMessage} ${!placesLimitExceeds ? selectedVal.trip_name : ''}`}
        showLinkLabel={!placesLimitExceeds}
        linkLabel={getDictionaryText('Undo')}
        onClick={handleUndoPlaces}
        handleClose={() => {
          toast.dismiss()
        }}
      />
      , isRTL
    )
  }


  const createItinerary = () => {
    setTimeout(() => {
      props.setIsOpen(false)
    }, 200);
    setTimeout(() => {
      props.createNewItinerary(true, itemUniqueKey)
    }, 200)
  }

  const renderModalContent = () => {
    return (
      <>
        <Typography variant={'h4'}>{getDictionaryText('AddToItinerary')}</Typography>
        <RadioType2
          list={itineraryList}
          selectedVal={val => {
            setSelectedVal(val)
            if (!val) {
              createItinerary()
            }
          }}
        />
        <Box className="btnWrapper btn-add-itinerary">
          <Button
            // hideEndIcon={isMobile}
            type="button"
            buttonType="form"
            size="small"
            hasBorder={false}
            onClick={addPlaceToLastDay}
            disabled={!selectedVal}
            endIconSvg={createSVG('arrowIcon', '#FFFF', '')}
          >
            {getDictionaryText('addPlaceItinerary')}
          </Button>
          {/* <Link customHref={CTALink?.value?.href} isCustom={true} className="viewAllBtn">
            <Typography variant="body2Bold">
              {getDictionaryText('ViewAll')}
            </Typography>
          </Link> */}
        </Box>
      </>
    );
  };
  return (
    <>
      {renderModalContent()}
    </>
  );
};

export default AddItinerary;
