import { toast } from 'react-toastify';

export const showToast = (message, isRTL, onCloseCallback = () => {}) => {
  const toastOptions = {
    autoClose: 3000,
    icon: false,
    position: isRTL ? 'bottom-right' : 'bottom-left',
    theme: 'light',
    onClose: onCloseCallback,
    closeOnClick: false,
    hideProgressBar: true,
  };

  try {
    // toast.dismiss()
    const options = toastOptions;
    toast.success(message, options);
  } catch (err) {
    console.log('this is err on toast-------', err);
  }
};
