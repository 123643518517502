import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    position: 'relative',
    '& .card-description': {
      paddingTop: pxToRem(24),
      paddingBottom: pxToRem(24),
      ...theme.mixins.paddingLeft(0),
      ...theme.mixins.paddingRight(theme.spacing(2)),
      [theme.breakpoints.up('lg')]: {
        minHeight: pxToRem(176),

      },
      // [theme.breakpoints.down('lg')]: {
      //   maxHeight: '212px',
      // },
    },
    '& .listing-card .card-description': {
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper img, img': {
        transition: 'none',
        transform: 'scale3d(1, 1, 1)',
      },
      '& .rating-img': {
        width: 'auto',
        maxHeight: pxToRem(13),
      },
    },
    '& .search-highlight': {
      backgroundColor: theme.palette.common.Highlight,
      color: theme.mixins.Black(),
    },


  },

  locationTitleTop: {
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  '.setMinHeight': {
    minWidth: '10px',
  },

  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'box-shadow .3s, background-color .3s',
    height: '100%',


    '&:hover': {
      boxShadow: theme.shadows[1],
      backgroundColor: theme.mixins.White(),
      '& h3': {
        color: theme.palette.common.SeaPort,
      },
      '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
      '& .icon img': {
        transform: 'none',
      },
    },

    '& .loaderAlignment': {
      minHeight: '410px',
    },

    '& .loaderCard': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      backgroundColor: '#eee',
      color: 'rgb(255, 135, 7)',
    },


    '& .MuiChip-root': {
      cursor: 'pointer',
      ...theme.mixins.marginRight('4px'),
      '&:last-child': {
        ...theme.mixins.marginRight('0'),
      },
    },
    '&.listing-card': {
      boxShadow: theme.shadows[4],
      backgroundColor: theme.mixins.White(),
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '&:hover': {
        transition: 'box-shadow .3s',
        boxShadow: theme.shadows[1],
        '& .card-description': {
          boxShadow: 'none',
          backgroundColor: 'none',
        },
      },
      '& .card-description': {
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        borderBottom: '0',
        '& .contentLeftBar': {
          width: '10px',
          paddingLeft: '10px',
          height: 'auto',
          background: theme.palette.common.SeaGreen,
          ...theme.mixins.marginRight(pxToRem(12)),
        },
        '& h3': {
          minHeight: 'auto',
        },
        '& .card-description-flex': {
          flex: 'auto',
          // position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 'calc(100% - 20px)',
          overflow: 'hidden',
          '& .card-description-wrapper': {
            '& .customLink': {
              '&::after': {
                width: '100%',
                height: '100%',
                position: 'absolute',
                content: '""',
                top: '50%',
                left: '50%',
                transform: 'translate(-50% , -50%)'
              },
              '&:hover': {
                backgroundColor: theme.mixins.White(),
                '& h3': {
                  color: theme.palette.common.SeaPort,
                },
                '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
                  transform: 'scale3d(1.1, 1.1, 1.1)',
                },
                '& .icon img': {
                  transform: 'none',
                },
              },
            },
          },

          '& .tagswrapper': {
            '&.calc-done': {
              '& .MuiChip-root': {
                '&:nth-last-child(2)': {
                  minWidth: '10px',
                },
              },
            },
            '&.calc-done-last': {
              '& .MuiChip-root': {
                '&:last-child': {
                  minWidth: '10px',
                },
              },
            },
          },
        },
      },
      '&.horizontal-card': {
        flexDirection: 'row',
        '& .card-description': {
          padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(
            3
          )
            }`,
          ...theme.mixins.paddingLeft(pxToRem(20)),
          [theme.breakpoints.up('lg')]: {
            flex: '0 0 75%',
            maxWidth: '75%',
            margin: 0,
            padding: theme.spacing(3),
          },
          '& h3': {
            marginBottom: theme.spacing(1),
          },
          '& p': {
            color: theme.palette.text.darkGrey,
            marginBottom: theme.spacing(2),
            ...theme.palette.common.ellipsis(2),
            maxHeight:
              theme.typography.body2.lineHeight.replace('rem', '') * 2 + 'rem',
            [theme.breakpoints.up('lg')]: {
              ...theme.palette.common.ellipsis(3),
              maxHeight:
                theme.typography.body2.lineHeight.replace('rem', '') * 3 +
                'rem',
            },
          },
          '& .contentLeftBar': {
            width: pxToRem(0),
            paddingLeft: '0px',
            height: '100%',
            background: 'transparent',
            ...theme.mixins.marginRight(pxToRem(0)),
          },
        },
        '& .img-wrapper': {
          flex: '0 0 25%',
          overflow: 'hidden',
          margin: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(3)}`,
          ...theme.mixins.marginRight(0),
          [theme.breakpoints.up('lg')]: {
            margin: 0,
          },
          '& img': {
            display: 'block',
            height: '100%',
          },
        },
      },
    },
    '& .common-card': {
      '&.card-description': {
        backgroundColor: 'transparent',
        boxShadow: theme.shadows[0],
        display: 'flex',
        flexDirection: 'row',
        // flex: 1,
        flex: 0,
        borderBottom: '0',
        padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(0)}`,
        [theme.breakpoints.up('lg')]: {
          padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(0)}`,
        },

        '& .contentLeftBar': {
          width: '7px',
          paddingLeft: '7px',
          height: '100%',
          background: theme.palette.common.SeaGreen,
          ...theme.mixins.marginRight(pxToRem(18)),
        },
        '& .card-description-flex': {

          flex: 'auto',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      },
    },

    '& .common-card-description': {
      flex: 1,
    },
  },
  rtlClass: {
    direction: theme.direction,
    height: '100%',
  },

  imageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '190px',
    [theme.breakpoints.up('md')]: {
      minHeight: '416px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '200px',
    },
    '& picture': {
      paddingTop: '0',
    },
    '& :not(.img-to-animate-wrapper) img, & .img-to-animate-wrapper': {
      transition: 'all 1s ease',
    },
    '&.collage': {
      marginBottom: theme.spacing(4),
    },
  },

  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: theme.mixins.textLeft('left'),
    '& .MuiTypography-cardTitle1': {
      marginBottom: theme.spacing(1),
      ...theme.palette.common.ellipsis(2),
    },
    '& .MuiTypography-cardTitle2': {
      marginBottom: theme.spacing(1),
      ...theme.palette.common.ellipsis(3),
    },
    '& .bot-area': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: '1px',
    },
    '& .cta-wrap': {
      marginTop: 'auto',
      display: 'flex',
      '&.tagswrapper': {
        display: 'block',
      },
    },
    '& .hidden-tags-for-calc': {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
  },
  location: {
    display: 'flex',
    '&.MuiTypography-root': {
      color: theme.palette.text.tertiary,
      marginBottom: theme.spacing(2),
      '& span': {
        ...theme.palette.common.ellipsis(2),
      },
    },
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiTypography-root': {
      color: theme.palette.text.tertiary,
      marginBottom: theme.spacing(2),
    },
    '& .icon': {
      ...theme.mixins.marginRight(theme.spacing(1)),
      '& svg': {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
    },
  },
  highlights: {
    display: 'inline-flex',
    alignItems: 'center',
    '&.MuiTypography-root': {
      color: theme.mixins.Black(),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '& .icon': {
      marginRight: theme.spacing(1),
      '& img': {
        width: pxToRem(20),
        height: pxToRem(20),
      },
    },
  },
  rating: {
    ...theme.typography.labelRegular,
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    '& svg': {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      marginRight: theme.spacing(0.5),
    },
  },
  posRelative: {
    position: 'relative',
  },
  viewsCounterTag: {
    position: 'absolute',
    top: pxToRem(16),
    ...theme.mixins.left(pxToRem(16)),
  },
  favViewsCounterTag: {
    position: 'absolute',
    top: 0,
    ...theme.mixins.left(0),
  },
  priceRangeTag: {
    position: 'absolute',
    bottom: pxToRem(16),
    ...theme.mixins.left(pxToRem(16)),
  },
  favPriceRangeTag: {
    position: 'absolute',
    bottom: 0,
    ...theme.mixins.right(0),
  },
  overViewSection: {
    paddingBottom: pxToRem(10.35),
    width: '100%',
    marginTop: pxToRem(12),
    overflow: 'hidden',
    '& .ratign-sec': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .open-close-sec': {
      display: 'flex',
      marginTop: pxToRem(5),
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: pxToRem(16),
    },
  },
  ratingImageWithLogo: {
    display: 'flex',
    '& .MuiSvgIcon-root': {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  totalReviews: {
    padding: '0',
    color: theme.palette.text.tertiary1,
  },
  ratingTripAdvisor: {
    display: 'inline-flex',
    background: theme.palette.background.transparent,
    ...theme.mixins.marginLeft(pxToRem(-5)),
    marginTop: pxToRem(-1),
  },
  descriptionAlignment: {
    marginBottom: pxToRem(20),
    display: 'flex',
    flexGrow: 1,
    ...theme.palette.common.ellipsis(8),
  },
  listItemContainer: {
    height: '100%',
  },

  customWrapper: {
    width: 'calc(100% + 32px)',
    '& .categoryAlign': {
      '& .MuiChip-label': {
        '& .MuiTypography-root': {
          maxWidth: pxToRem(104),
          [theme.breakpoints.down('md')]: {
            maxWidth: pxToRem(88),
          },
          '@media (max-width: 360px)': {
            maxWidth: pxToRem(68),
          }
        }
      }
    }
  }

}));

export default useStyles;
