import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import {
  fontFamilyEN as fontFamily,
  fontSize,
} from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  root: {
    '&.itineraryModal': {
      borderRadius: pxToRem(12),
      boxShadow: '0px 0px 50px 0px rgba(0, 0, 0, 0.30)',
      maxWidth: '600px',
      margin: 'auto',
      height: 'fit-content',
      // width: 'fit-content',
      zIndex: '1400 !important',
      [theme.breakpoints.down('sm')]: {
        // marginBottom: '0',
        maxWidth: '100%',
      },

      '&.itineary-modal-mob': {
        zIndex: '999 !important',
      },

      '&.addItenary': {
        maxWidth: '500px',
        height: 'fit-content',

        '& > div': {
          '&:first-child': {
            backgroundColor: '#00000040 !important',
          },
        },

        '& .crossWraper': {
          top: '17.33px',
          ...theme.mixins.right(pxToRem(17.33)),
          [theme.breakpoints.down('sm')]: {
            top: pxToRem(32),
            ...theme.mixins.right(pxToRem(24)),
          },
          '& span': {
            '& svg': {
              '& path': {
                stroke: '#898C8D'
              }
            }
          }
        },
        '& h4': {
          fontSize: pxToRem(32),
          lineHeight: pxToRem(40),
          [theme.breakpoints.down('sm')]: {
            fontSize: pxToRem(24),
            lineHeight: pxToRem(32),
          },
        },
        '& svg': {
          width: '16px',
          height: '16px',
          // '& path': {
          //   stroke: '#898C8D'
          // },
        },
        [theme.breakpoints.down('sm')]: {
          marginBottom: '0',
          maxWidth: '100%',
        },
      },
      '&.createItinerary': {
        maxWidth: '632px',
        '& > div': {
          '&:first-child': {
            [theme.breakpoints.down('sm')]: {
              backgroundColor: 'transparent !important',
            },

          },
        },
        [theme.breakpoints.up('md')]: {
          // maxWidth: 'unset',
          width: 'fit-content',
        },
        [theme.breakpoints.down('md')]: {
          // maxWidth: 'unset',
          maxWidth: '100%'
        },
        [theme.breakpoints.down('sm')]: {
          maxWidth: '100%',
          boxShadow: 'none',
          // overflow: 'scroll',
          // height: 'calc((var(--vh, 1vh) * 100))',
          height: 'calc(100% - var(--headerHeight) - var(--notificationBar)) ',
          marginTop: 'auto',
          margin: '0',
          '& > div': {
            '&:first-child': {
              backgroundColor: 'transparent !important',
            },
          },
        },
        '& .boxWrapper': {
          padding: '48px',
          [theme.breakpoints.up('md')]: {
            padding: '24px 48px 48px 48px',
          },
          [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            overflow: 'scroll',
            height: '100%',
            padding: '0px 24px 16px 24px',
          },
          '& .crossWraper': {
            width: '100%',
            position: 'unset',
            top: 'unset',
            minHeight: pxToRem(40),
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              marginTop: '16px',
              marginBottom: '16px'
            },
            '& span': {
              cursor: 'pointer',
              width: 'fit-content',
              textAlign: 'unset',
              display: 'flex',
              alignItems: 'center',
              '[dir="rtl"] &': {
                '& svg': {
                  marginLeft: pxToRem(12),
                  transform: 'rotate(180Deg)',
                }
              },
            },
            '& .text': {
              fontSize: '16px',
              lineHeight: '24px',
              textTransform: 'uppercase',
              fontWeight: '400',
              color: '#898C8D',
              padding: '8px 0',
              marginLeft: '12px',
            },
          },
          '& .formWrapper': {
            '& .MuiTypography-h4': {
              [theme.breakpoints.up('md')]: {
                lineHeight: '40px',
                fontSize: '32px',
                marginBottom: '24px',
                marginTop: '8px',
              },
              [theme.breakpoints.down('sm')]: {
                marginTop: '0',
              }
            },

            '&.itinerary-alignment': {
              [theme.breakpoints.up('md')]: {
                '& .MuiButtonBase-root': {
                  marginTop: pxToRem(41),
                }
              }
            },

            '& .MuiGrid-root': {
              // [theme.breakpoints.up('md')]: {
              '&:first-child': {
                [theme.breakpoints.up('md')]: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  '& .MuiFormControl-root': {
                    maxWidth: 'unset',
                  },
                }
              },
              '& .MuiFormControl-root': {
                [theme.breakpoints.up('md')]: {
                  '& .makeStyles-rangeCalendar-707': {
                    // minHeight: '82px',
                  },
                  '& .MuiInputBase-root': {
                    height: '51px',
                    padding: '0  16px',
                    borderRadius: '3px',
                    '& input': {
                      padding: 'unset',
                      '&:-webkit-autofill': {
                        boxShadow: 'inset 0 0 0 1000px #fff',
                        '-webkit-text-fill-color': '#565A5C'
                      },
                      '&:-webkit-autofill:focus ': {
                        boxShadow: 'inset 0 0 0 1000px #fff',
                        '-webkit-text-fill-color': '#565A5C'
                      }
                    }
                  },
                  '& .rangeCalendarPicker': {
                    padding: '13px 16px',
                    height: '51px',
                    // height: '100%',
                    // background: 'red'
                  }
                },
                '& .rangeCalendarPicker': {
                  '@media (max-width: 1439px)': {
                    padding: '0 16px',
                    display: 'flex',
                    alignItems: 'center',
                    '& .calendarIcon': {
                      top: pxToRem(22)
                    },
                    [theme.breakpoints.down('md')]: {
                      padding: '13px 16px',
                      '& .calendarIcon': {
                        top: pxToRem(16)
                      },
                    }
                  },
                  // [theme.breakpoints.down('md')]: {
                  //   padding: '16px',
                  // },
                }
              },
              '& .customRadio': {
                [theme.breakpoints.up('md')]: {
                  marginTop: 'unset',
                  '& .MuiFormLabel-root': {
                    marginBottom: '12px',
                  },
                  '& .MuiFormGroup-root': {
                    '& .MuiFormControlLabel-root': {
                      width: '125px',
                      height: '40px',
                      ...theme.mixins.marginRight(pxToRem(12)),
                      '& .MuiButtonBase-root': {
                        width: '125px',
                        height: '40px',
                        borderRadius: '72px',
                      }
                    }
                  }
                }
              },
              '& .counterBox': {
                [theme.breakpoints.up('md')]: {

                  '& .counterWrapper': {
                    '& .MuiButtonBase-root': {
                      borderRadius: '70%',
                    }
                  }
                },
                [theme.breakpoints.down('sm')]: {
                  marginBottom: pxToRem(20),
                }
              },
              '& .MuiButtonBase-root': {
                [theme.breakpoints.up('md')]: {
                  '&.MuiButton-sizeSmall': {
                    marginTop: pxToRem(32),
                    padding: '12px 24px',
                  }
                }
              }
              // },
            },


            '& form': {
              position: 'relative',
              '& .deleteBtn': {
                position: 'absolute',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                bottom: '9px',
                cursor: 'pointer',
                color: '#FF8707',
                ...theme.mixins.marginLeft(24),
                ...theme.mixins.left(192),
                [theme.breakpoints.up('lg')]: {
                  ...theme.mixins.marginLeft(24),
                  ...theme.mixins.left(175),
                  bottom: '4px',
                },
                [theme.breakpoints.up('xxl')]: {
                  bottom: '9px',
                  ...theme.mixins.marginLeft(24),
                  ...theme.mixins.left(192),
                },
              },
            },
            '& .formHeading': {
              [theme.breakpoints.down('sm')]: {
                marginTop: '40px',
                marginBottom: '24px',
                maxWidth: '211px',
                width: '100%',
              },
            },
          },
        },
      },
      '& .boxWrapper': {
        borderRadius: pxToRem(12),
        position: 'relative',
        padding: '32px',
        '&.disable-cta': {
          '& .deleteCta': {
            pointerEvents: 'none',
            opacity: 0.5
          }
        },
        '&.sty2': {
          '& .radioGroup': {
            '& .MuiButtonBase-root': {
              borderRadius: pxToRem(8),
              [theme.breakpoints.down('sm')]: {
                borderRadius: pxToRem(4),
              },
            }
          }
        },
        [theme.breakpoints.down('sm')]: {
          padding: pxToRem(24),
          borderRadius: '0px',
          borderTopLeftRadius: pxToRem(16),
          borderTopRightRadius: pxToRem(16),
        },
        backgroundColor: '#fff',
        '& .crossWraper': {
          position: 'absolute',
          top: '24px',
          ...theme.mixins.right(pxToRem(24)),
          width: '24px',
          '& svg': {
            [theme.breakpoints.down('sm')]: {
              width: '16px',
              top: '16px',
              ...theme.mixins.right(pxToRem(20)),
            },
          },
          '& span': {
            cursor: 'pointer',
            width: '100%',
            display: 'block',
            textAlign: 'center',
          },
        },
        '& .btnWrapper': {
          display: 'flex',
          alignItems: 'center',
          '& .viewAllBtn': {
            color: '#000000',
            ...theme.mixins.marginLeft(pxToRem(24)),
            [theme.breakpoints.down('sm')]: {
              color: '#FF8707',
            },
          },
          '&.btn-add-itinerary': {
            '& .MuiIcon-root': {
              width: 'unset',
              height: 'unset',
            },
            '& svg': {
              width: 'unset',
              height: 'unset',
              '[dir="rtl"] &': {
                transform: 'rotate(180Deg)'
              },
            },
          }
        },
        '& .formWrapper': {
          '& .MuiTypography-h4': {
            display: 'block',
            margin: 0,
            fontWeight: '700',
            marginBottom: '40px',
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
              fontSize: pxToRem(24),
              lineHeight: pxToRem(32),
              maxWidth: '80%',
              marginTop: '56px',
              marginBottom: '16px',
            },
          },
          '&.itinerary-alignment': {
            '& .MuiButtonBase-root': {
              marginTop: pxToRem(24),
              [theme.breakpoints.down('sm')]: {
                marginTop: pxToRem(32),
              },
              '& .MuiButton-label': {
                '& .btn-txt': {
                  fontWeight: '700',
                  fontSize: '16px',
                },
              },
            },
          },
          '&.labelAlingment': {
            '& .customRadio': {
              '& .MuiFormControlLabel-root': {
                width: '153.5px !important',
                '& .radio-btn': {
                  width: '153.5px !important',
                },
              },
            },
          },
          '& .MuiGrid-root': {
            '& .MuiFormControl-root': {
              maxWidth: '244px',
              width: '100%',
              margin: 0,
              '& .react-datepicker__day': {
                '&:focus-visible': {
                  outline: '2px solid #000',
                  borderRadius: '2px'
                }
              },
              '& .react-datepicker__navigation': {
                borderColor: '#000'
              },
              '&.customInput': {
                ...theme.mixins.marginRight(pxToRem(16)),
                [theme.breakpoints.down('md')]: {
                  minHeight: '78px',
                  marginBottom: '16px',
                  justifyContent: 'flex-end',
                },
                [theme.breakpoints.down('sm')]: {
                  minHeight: 'auto',
                },
                '& .MuiFormLabel-root': {
                  lineHeight: '16px',
                  marginBottom: '8px',
                  fontSize: '14px',
                  [theme.breakpoints.down('sm')]: {
                    transform: 'translate(0, 0px) scale(1)',
                  },
                },
              },
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
              },
              '& .MuiInputBase-root': {
                borderColor: '#DDDEDE',
                [theme.breakpoints.down('sm')]: {
                  borderRadius: '3px'
                },
                '&.Mui-focused': {
                  borderColor: '#FF8707',
                },
                '& input': {
                  color: '#565A5C',
                  fontSize: '14px',
                },
              },
              '& .calendarWrapper': {
                marginTop: pxToRem(24)
              },
              '& .rangeCalendarPicker': {
                '&.singleCalendarfocused': {
                  borderColor: '#FF8707',
                },
                borderColor: '#DDDEDE',
                top: pxToRem(24),
                [theme.breakpoints.up('md')]: {
                  top: pxToRem(27.4),
                },
                [theme.breakpoints.up('lg')]: {
                  top: '24px',
                },
                [theme.breakpoints.up('xl')]: {
                  top: pxToRem(30),
                },
                [theme.breakpoints.up('xxl')]: {
                  top: pxToRem(24),
                },
                '& div': {

                  fontSize: '14px',
                  '&.sty1': {
                    '[dir="rtl"] &': {
                      ...theme.mixins.paddingLeft(0)
                    },
                  }
                },
                '& label': {
                  '@media (max-width: 1439px)': {
                    transform: 'translate(0, -3.4rem) scale(0.9)',
                  },
                  '@media (max-width: 1023px)': {
                    transform: 'translate(0, -3.1rem) scale(0.9)',
                  },
                  '@media (max-width: 767px)': {
                    transform: 'translate(0, -2.59375rem) scale(0.9)',
                  },
                  '& .MuiTypography-root': {
                    lineHeight: '16px',
                    color: '#000000',
                  },
                },
                '& .MuiTypography-root': {
                  fontSize: '14px',
                  color: '#565A5C',
                },
              },
            },
            '& .customRadio': {
              marginTop: pxToRem(16),
              width: '100%',
              maxWidth: '100%',
              [theme.breakpoints.down('md')]: {
                marginTop: pxToRem(0),
              },
              // [theme.breakpoints.down('sm')]: {
              //   marginTop: pxToRem(16),
              // },
              '& .MuiFormLabel-root': {
                fontSize: '14px',
                fontWeight: '400',
                marginBottom: '16px',
                fontFamily: 'BasicAbuDhabi-Regular, Arial, sans-serif',
                lineHeight: '16px',
              },
              margin: 0,

              '& .MuiFormGroup-root': {
                width: 'calc(100% + 12px)',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'space-between',
                },
                '& .MuiFormControlLabel-root': {
                  width: '114px',
                  height: '114px',
                  ...theme.mixins.marginLeft(pxToRem(0)),
                  ...theme.mixins.marginRight(pxToRem(16)),
                  [theme.breakpoints.down('sm')]: {
                    width: '155.5px',
                    height: '48px',
                    ...theme.mixins.marginRight(pxToRem(0)),
                    marginBottom: '16px',
                  },
                  '&:last-child': {
                    ...theme.mixins.marginRight(pxToRem(0)),
                  },
                  '& .MuiButtonBase-root': {
                    border: '1px solid #DDDEDE',
                    position: 'absolute',
                    width: '114px',
                    height: '114px',
                    '&.radio-btn': {
                      marginTop: 0,
                    },
                    [theme.breakpoints.down('sm')]: {
                      // width: '163.5px',
                      width: '155.5px',
                      height: '48px',
                      borderRadius: '72px',
                    },
                    '&.Mui-checked': {
                      border: '1px solid #FF8707',
                    },
                    '&.Mui-checked + span': {
                      color: '#FF8707',
                    },
                    '& .MuiIconButton-label': {
                      display: 'none',
                    },
                  },
                  '& .MuiFormControlLabel-label': {
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    color: '#565A5C',
                    '& .MuiTypography-root': {
                      fontSize: pxToRem(14),
                      fontWeight: '700',
                      position: 'absolute',
                      lineHeight: pxToRem(16),
                      top: '50%',
                      left: '50%',
                      width: '100%',
                      textAlign: 'center',
                      transform: 'translate(-50% , -50%)',
                    },
                  },
                },
              },
            },
            '& .counterBox': {
              // marginBottom: pxToRem(24),
              '& .MuiTypography-root': {
                margin: '16px 0',
                fontSize: '14px',
                lineHeight: '16px',
                [theme.breakpoints.down('sm')]: {
                  marginTop: pxToRem(0),
                },
              },
              '& .errortext': {
                marginTop: '20px',
                margin: 0,
                display: 'block',
                [theme.breakpoints.down('sm')]: {
                  marginTop: '20px !important',
                },
              },
              '& .counterWrapper': {
                display: 'flex',
                alignItems: 'center',
                '& .MuiButtonBase-root': {
                  marginTop: 0,
                  minHeight: pxToRem(48),
                  minWidth: pxToRem(48),
                  border: '1px solid #FF8707',
                  backgroundColor: '#FF8707',
                  borderRadius: '50%',
                  touchAction: 'manipulation',
                  '&.Mui-disabled': {
                    backgroundColor: 'transparent',
                    opacity: 1,
                    borderColor: '#DDDEDE',
                    color: '#D9D9D9',
                  },
                  '& .MuiButton-label': {
                    width: '100%',
                    height: '100%',
                  },
                },
                '& .valueWrapper': {
                  maxWidth: pxToRem(162),
                  width: '100%',
                  minHeight: pxToRem(48),
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #FF8707',
                  borderRadius: '72px',
                  justifyContent: 'center',
                  margin: '0 16px',
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: pxToRem(215),
                  },
                  '& .MuiTypography-root': {
                    color: '#565A5C',
                    margin: 'auto',
                  },
                },
              },
            },
            '& .formCustomBtn': {
              fontSize: '16px',
              lineHeight: '22px',
              color: '#FF8707',
              marginTop: pxToRem(24),
              // '&.MuiButton-sizeSmall': {
              //   ...theme.mixins.marginRight(24)
              // }
              // [theme.breakpoints.down('sm')]: {
              //   ...theme.mixins.marginLeft(0),
              //   display: 'block'
              // },
            },
            '& .MuiButtonBase-root': {
              marginTop: pxToRem(24),
              margin: 0,
              '&.create-itinerary-submit-btn-alignment': {
                [theme.breakpoints.down('sm')]: {
                  marginTop: pxToRem(8),
                  width: '100%',
                },
              },
              '&.MuiButton-sizeSmall': {
                ...theme.mixins.marginRight(20)
              },
              [theme.breakpoints.down('sm')]: {
                marginTop: pxToRem(24),
              },
            },
          },
        },
      },
    },
  },
}));

export default useStyles;