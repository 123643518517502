import React from 'react';
import Typography from '../../atoms/Typography';
import Picture from '../../../components/atoms/Picture';
import ImagePlaceholder from '../../../../assets/svg/ImagePlaceholder.svg';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import ItineraryPlaceholder from '../../../../assets/images/itineraryPlaceholder.png'
import { useHistory } from 'react-router-dom';
import { canUseDOM } from '../../../utils/canUseDOM';
import { useSelector } from 'react-redux';
import { get, keys, last } from 'lodash';
import aspectRatios from '../../../utils/AspectRatios';

const ItineraryBuilderList = props => {
  const { itineraryList, redirectLink, ...rest } = props;
  let recentItem = Array.isArray(itineraryList) && itineraryList[itineraryList?.length - 1];
  let placesExist = recentItem && recentItem?.newdays && Object.values(recentItem?.newdays).some(place => place.length > 0);
  const tripDaysText = recentItem && Number(recentItem?.trip_days) > 1 ? `${recentItem?.trip_days} ${getDictionaryText('Days')}` : `${recentItem?.trip_days} ${getDictionaryText('ItineraryDay')}` || ''
  const history = useHistory()

  const createSVG = (type = '', fill = '', customClass = '') => {
    switch (type) {
      case 'clockIcon':
        return (
          <svg className={`${customClass} customSVG`} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.101 7H11.3028V13.2131L17.5964 17L18.4955 15.5213L13.101 12.3115V7Z" fill={fill} />
            <ellipse cx="12.3303" cy="12" rx="10.2752" ry="10" stroke={fill} strokeWidth="2" />
          </svg>
        )
        break;
      default:
        return <></>
    }
  }
  const { placesItinerary } = useSelector(state => {
    return state.ItineraryBuilderMapReducer
  });

  const getThumbnailImage = () => {
    const lastPlace = last(placesItinerary);
    const thumbImage = get(lastPlace, 'newdays', {});
    const keysArray = keys(thumbImage);

    for (const key of keysArray.reverse()) {
      const firstDayArray = get(thumbImage, key, []);

      if (firstDayArray.length > 0 && get(firstDayArray[0], 'thumbnailImage')) {
        const thumbnailImage = get(firstDayArray[0], 'thumbnailImage', ImagePlaceholder);
        return thumbnailImage;
      }
    }

    return ImagePlaceholder;
  };

  const renderImage = () => {
    if (!placesExist)
      return ItineraryPlaceholder
    else {
      //places will be fetched and first place of itinerary image will be displayed
      return getThumbnailImage()
    }
  }

  const handleClick = () => {
    if (canUseDOM) {
      const redirectURL = `${window.location.origin}${redirectLink?.value?.href}?itineraryId=${recentItem?.id}`;
      window.location.href = redirectURL;
    }
  }

  return (
    <React.Fragment>
      {recentItem && <div tabIndex={0} className={`itenaryListingWrapper ${!placesExist ? 'emptyList' : ''}`} onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleClick()
        }
      }} onClick={() => handleClick()}>
        <div className={`boxImg ${!placesExist ? 'itineraryImg' : 'normalImg'}`}>
          <Picture

            media={{
              image: { value: renderImage() },
              // disableLazyLoad: true,
              fallbackBackgroundNeeded: placesExist ? true : false,
              aspectRatio: aspectRatios['1x1-card'],
            }}
            isParalax={false}

          />
        </div>
        <div className='boxDescription'>
          <Typography
            variant="body2Bold"
            component="span"
            className="headingInner"
          >

            {`${recentItem?.trip_name} ${placesExist ? '' : getDictionaryText('NoPlaceItinerary')}`}
          </Typography>
          <div className='timeLabel'>
            {createSVG('clockIcon', '#000000', '')}
            <Typography
              variant="body3"
              component="span"
            >
              {tripDaysText}
            </Typography>
          </div>
        </div>
      </div>}
    </React.Fragment>
  );
};

ItineraryBuilderList.prototype = {

};

ItineraryBuilderList.defaultProps = {

};

export default ItineraryBuilderList;
