import React from 'react';
import PropType from 'prop-types';
import Typography from '../../atoms/Typography';

import FavoriteIcon from '../FavoriteIcon';
import clsx from 'clsx';

import useStyles from './style';
import ButtonMUI from '../../atoms/Button';
import FavoriteItem from '../../atoms/FavoriteItem';
import { getDictionaryText } from '../../../utils/getDictionaryText';
import { useSelector } from 'react-redux';

const FavoriteList = props => {
  const { favoritesList, isMobile, ...rest } = props;
  const classes = useStyles();
  const { CTALink } = useSelector(state => state.FilteredDataReducer);

  return (
    <React.Fragment>
      {/* {isMobile &&
        <>
          <ButtonMUI classes={{ root: classes.title }} linkCustomStyle={CTALink ? 'fav-cta-align' : ''} field={CTALink} >
            {getDictionaryText('favorites')}
          </ButtonMUI>
        </>
      } */}
      <ul className={classes.favList}>
        {favoritesList.map(item => {
          return (
            <FavoriteItem key={item.itemUniqueKey} item={item} {...rest} />
          );
        })}
      </ul>
    </React.Fragment>
  );
};

FavoriteList.prototype = {};

FavoriteList.defaultProps = {};

export default FavoriteList;
