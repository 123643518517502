import makeStyles from '@material-ui/core/styles/makeStyles';
import { pxToRem } from '../../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => {
  const { marginLR, paddingLR } = theme.mixins;
  return {
    root: {
      margin: 0,
      marginBottom: pxToRem(32),
      [theme.breakpoints.up('lg')]: {
        margin: '0.5rem',
        marginBottom: pxToRem(32),
      },
    },
    uploadButton: {
      '&:not(.Mui-disabled):hover': {
        '& .MuiButton-label': {
          textDecoration: 'none',
        },
        '& .MuiButton-endIcon': {
          overflow: 'hidden',

          '& .MuiIcon-root': {
            animation: 'none',

            '& svg': {
              transform: 'rotate(270deg)',
            },
          },
        },
      },
      '& .MuiButton-label': {
        fontFamily: theme.custom.fontFamily.primaryBold,
        fontSize: fontSize.s16Rem,
      },
      '& .MuiButton-endIcon': {
        ...marginLR(pxToRem(16), 0),
        '& .MuiIcon-root': {
          '& svg': {
            transform: 'rotate(270deg)',
          },
        },
      },
      '&.Mui-disabled': {
        '& .MuiButton-endIcon': {
          backgroundColor: theme.mixins.Black(200),
        },
      },
    },
    error: {
      marginTop: pxToRem(8),
      color: theme.mixins.AbuDhabiOrange(),
    },
    supportedDocsText: {
      '& .richText': {
        '& ul': {
          ...paddingLR(pxToRem(16), 0),
          color: theme.mixins.Black(600),
        },
      },
    },
    inputLabel: {
      marginBottom: pxToRem(24),
    },
    noFileMessage: {
      marginTop: pxToRem(16),
    },
    docsList: {
      marginTop: pxToRem(16),
      display: 'flex',
      flexDirection: 'column',
    },
    fileName: {
      flexBasis: '70%',
      ...theme.palette.common.ellipsis(1),
    },
    docsRow: {
      paddingBottom: pxToRem(16),
      paddingTop: pxToRem(16),
      borderBottom: theme.borders.divider,

      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    responsiveSection: {
      marginTop: pxToRem(6),
      display: 'flex',
      justifyContent: 'space-between',

      [theme.breakpoints.up('lg')]: {
        flexBasis: '30%',
        margin: 0,
        ...marginLR(pxToRem(25), 0),
      },
    },
    removeLink: {
      color: theme.mixins.AbuDhabiOrange(),
      cursor: 'pointer',
    },
  }
});

export default useStyles;